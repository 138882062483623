import {
  IPrice,
  IAdvisorProductType,
  IAdvisorProduct,
  IPromotion,
  PromotionType,
  IAdvisorStatus,
  IPromotionAdvisor,
} from "../../../types"
import { IPublicRuntimeConfig } from "../../../utils/runtimeConfig"

export const swapProductPrices = (price: IPrice) => ({
  price: price.strikethroughPrice!,
  strikethroughPrice: price.price,
})

export function pickProductByType<T extends Partial<IAdvisorProduct>>(
  products: Array<T> | null | undefined,
  productType: IAdvisorProductType
): T | undefined {
  if (!products) return undefined

  return products.find(({ type }) => type === productType)
}

export function hasFreeProductPromotion(
  promotion?: IPromotion | IPromotionAdvisor | null
): boolean {
  if (!promotion) {
    return false
  }

  return promotion.type === PromotionType.WELCOME_FREE_MINUTES
}

const isFreeChatDisabled = (
  productType: IAdvisorProductType,
  withFreePromotion: boolean,
  freeChatsEnabled: boolean
): boolean => productType === "chat" && withFreePromotion && !freeChatsEnabled

export function isProductFree(
  productType: IAdvisorProductType,
  getRuntimeConfig: () => IPublicRuntimeConfig,
  promotion?: IPromotion | IPromotionAdvisor | null
): boolean {
  const withFreePromotion = hasFreeProductPromotion(promotion)
  const { FREE_CHAT_IS_ENABLED } = getRuntimeConfig()

  return isFreeChatDisabled(
    productType,
    withFreePromotion,
    FREE_CHAT_IS_ENABLED
  )
    ? false
    : withFreePromotion
}

export const isCall = (productType: IAdvisorProductType) =>
  productType === "call"

function sortProductsByPrice(products: IAdvisorProduct[]) {
  return [...products].sort((a, b) => {
    const priceDiff = a.price - b.price

    if (priceDiff === 0 && a.price_sale !== null && b.price_sale) {
      return a.price_sale - b.price_sale
    }

    return priceDiff
  })
}

export const getCheapestProduct = (products: IAdvisorProduct[]) => {
  const availableProducts = products.filter((pr) => pr.is_available)

  if (availableProducts.length === 0) {
    return null
  }

  const [cheapestProduct] = sortProductsByPrice(availableProducts)

  return {
    price: cheapestProduct.price,
    priceSale: cheapestProduct.price_sale,
    withPrefix: availableProducts.length > 1,
  }
}

export function isReservation(
  product: IAdvisorProduct,
  advisorStatus: IAdvisorStatus | undefined
) {
  const isCallProduct = isCall(product.type)

  return (
    (advisorStatus === "busy" || advisorStatus === "offline") &&
    product.is_available &&
    isCallProduct
  )
}

import React, { MouseEventHandler } from "react"

import AdviqoButton from "../AdviqoButton"
import classes from "./LogoutButton.module.scss"

interface LogoutButtonProps {
  onClick: MouseEventHandler
  labelClassName?: string
  label?: string
}

const LogoutButton: React.FC<LogoutButtonProps> = ({
  onClick,
  label = "Abmelden",
  labelClassName,
}) => {
  return (
    <AdviqoButton
      color="custom"
      buttonClassName={classes.logoutButton}
      labelClassName={labelClassName}
      testId="navbar-logout"
      onClick={onClick}
      label={label}
      fullWidth
    />
  )
}

export default LogoutButton

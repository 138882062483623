import React from "react"
import IconBase from "./IconBase"
import { IIconProps } from "./types"
import { useCheckBrand } from "./utils/useCheckBrand"

const CallIcon: React.FC<IIconProps> = ({
  color = "currentColor",
  className,
}) => {
  const { isQuesticoBrand } = useCheckBrand()

  return (
    <IconBase className={className}>
      {isQuesticoBrand ? (
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M18.9005 18.7891L18.1435 19.551C17.1879 20.5137 16.1199 21 14.9733 21C13.4269 21 11.7587 20.1139 10.0172 18.362L5.62723 13.9449C4.25333 12.5632 3.40741 11.2222 3.11717 9.95716C2.76403 8.4194 3.21007 7.01221 4.4426 5.77272L5.20023 5.01139C5.6884 4.52046 6.47719 4.39976 7.08711 4.72589C8.23308 5.33519 9.42175 7.33601 9.55216 7.56232C9.7922 7.98129 9.86375 8.41302 9.76047 8.78556C9.68257 9.06874 9.506 9.29912 9.24922 9.45812C8.90243 9.75 8.49447 10.1162 8.42061 10.2067C7.98323 10.8566 8.03343 11.3557 8.59718 11.9226L12.0282 15.373C12.596 15.9463 13.0841 15.9927 13.7408 15.5465C13.8268 15.4763 14.1886 15.066 14.4811 14.7172C14.6975 14.3551 15.0529 14.1567 15.4863 14.1567C15.7702 14.1567 16.0737 14.2432 16.3616 14.4085C16.589 14.5437 18.5786 15.7391 19.1844 16.8916C19.5174 17.5223 19.4037 18.2837 18.9005 18.7891Z"
            stroke={color}
            strokeWidth="1.5"
            strokeLinecap="square"
          />
          <path
            d="M16.8589 7.0624L19.6159 4.28979"
            stroke={color}
            strokeWidth="1.5"
            strokeLinecap="square"
          />
          <path
            d="M13.5732 5.88286V3"
            stroke={color}
            strokeWidth="1.5"
            strokeLinecap="square"
          />
          <path
            d="M18.1333 10.2648H21"
            stroke={color}
            strokeWidth="1.5"
            strokeLinecap="square"
          />
        </svg>
      ) : (
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M20.9199 18.1C21.1199 17.5 21.1199 15.9 20.2199 15.4L17.6199 14.1C16.8199 13.7 15.8199 14 15.4199 14.8C15.2199 15.1 15.1199 15.3 15.1199 15.3C14.0199 15.3 11.8199 13.6 11.0199 12.9C10.2199 12.1 8.6199 9.89999 8.6199 8.79999C8.6199 8.79999 8.8199 8.7 9.1199 8.6C9.9199 8.2 10.3199 7.19999 9.91991 6.39999L8.6199 3.89999C8.1199 3.09999 6.51991 2.99999 5.91991 3.19999C4.81991 3.49999 3.91991 4.29999 3.41991 5.19999C2.71991 6.49999 3.01991 8.19999 3.41991 9.89999C4.21991 12.3 5.71991 14.5 7.71991 16.4C9.61991 18.4 11.8199 19.9 14.2199 20.7C15.9199 21.1 17.6199 21.4 18.9199 20.7C19.8199 20.1 20.6199 19.2 20.9199 18.1Z"
            stroke={color}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M14.3198 3C17.9198 3 20.8198 5.9 20.8198 9.5"
            stroke={color}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M14.3198 6.30005C16.1198 6.30005 17.6198 7.80005 17.6198 9.60005"
            stroke={color}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      )}
    </IconBase>
  )
}

export default CallIcon

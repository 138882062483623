"use client"

import { useContext, useCallback } from "react"
import { Breakpoint, useTheme } from "@mui/material/styles"

import ViewportContext from "./ViewportContext"

const useViewport = () => {
  const viewport = useContext(ViewportContext)
  const theme = useTheme()
  const { keys } = theme.breakpoints

  const isViewport = (breakpoint: Breakpoint): boolean =>
    viewport === breakpoint

  const isViewportUp = (breakpoint: Breakpoint): boolean => {
    switch (breakpoint) {
      case "xs":
        return true
      case "sm":
        return viewport !== "xs"
      case "md":
        return viewport !== "xs" && viewport !== "sm"
      case "lg":
        return viewport === "lg" || viewport === "xl"
      case "xl":
        return viewport === "xl"
      default:
        return false
    }
  }

  const isViewportDown = (breakpoint: Breakpoint): boolean => {
    switch (breakpoint) {
      case "xs":
        return viewport === "xs"
      case "sm":
        return viewport === "xs" || viewport === "sm"
      case "md":
        return viewport !== "lg" && viewport !== "xl"
      case "lg":
        return viewport !== "xl"
      case "xl":
        return true
      default:
        return false
    }
  }

  const isViewportBetween = useCallback(
    (firstBreakpoint: Breakpoint, secondBreakpoint: Breakpoint): boolean => {
      const currentIndex = keys.indexOf(viewport)
      const startIndex = keys.indexOf(firstBreakpoint)
      const endIndex = keys.indexOf(secondBreakpoint)

      return currentIndex >= startIndex && currentIndex <= endIndex
    },
    [JSON.stringify(keys)]
  )

  return {
    viewport,
    isViewportUp,
    isViewportDown,
    isViewport,
    isViewportBetween,
  }
}

export default useViewport

import React from "react"
import { ButtonProps } from "@mui/material/Button"

import { Button } from "../MaterialUI"

/** @see https://material-ui.com/guides/typescript/#usage-of-component-prop for details */
type IGenericAdviqoButtonProps<C extends React.ElementType> = ButtonProps<
  C,
  { component?: C }
>

function GenericAdviqoButton<C extends React.ElementType>({
  children,
  ...other
}: IGenericAdviqoButtonProps<C>): JSX.Element {
  return <Button {...other}>{children}</Button>
}

export default GenericAdviqoButton

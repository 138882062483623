import React, { useState, useCallback, useRef, ReactNode } from "react"
import DomRefsContext from "./DomRefsContext"
import { IDomRefsContext, TAddRef, TDelRef, TGetRefHeight } from "./types"

const DomRefsProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [refsMap, setRef] = useState<IDomRefsContext["refsMap"]>({})
  const withFiltersPortal = useRef(false)

  const addRef: TAddRef = useCallback((name, ref = null) => {
    setRef((prev) => ({ ...prev, [name]: ref }))
  }, [])

  const delRef: TDelRef = useCallback((name) => {
    setRef((prev) => ({ ...prev, [name]: undefined }))
  }, [])

  const getRefHeight: TGetRefHeight = useCallback(
    (name) => {
      return refsMap[name]?.current?.offsetHeight || 0
    },
    [refsMap]
  )

  return (
    <DomRefsContext.Provider
      value={{ refsMap, addRef, delRef, getRefHeight, withFiltersPortal }}
    >
      {children}
    </DomRefsContext.Provider>
  )
}
export default DomRefsProvider

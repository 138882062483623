import * as Sentry from "@sentry/nextjs"

const sentryUtils = {
  report: ({
    message,
    error,
    ...breadcrumb
  }: { message?: string; error?: unknown } & Partial<Sentry.Breadcrumb>) => {
    if (error) {
      if (message) {
        Sentry.addBreadcrumb({
          message,
          level: "info",
          ...breadcrumb,
        })
      }

      Sentry.captureException(error)
    } else if (message) {
      Sentry.captureMessage(message, { level: "info" })
    }
  },
}

export default sentryUtils

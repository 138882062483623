import React, { Suspense, ComponentProps } from "react"

const DateField = React.lazy(() => import("./DateField"))

const LazyDateField: React.FC<ComponentProps<typeof DateField>> = ({
  date,
  label = "Date",
  className,
  onChange,
  format,
  placeholder,
  keyboardIcon,
  onError,
}) => {
  return (
    <Suspense fallback={null}>
      <DateField
        date={date === undefined ? null : date}
        label={label}
        placeholder={placeholder}
        keyboardIcon={keyboardIcon}
        className={className}
        onChange={onChange}
        format={format}
        onError={onError}
      />
    </Suspense>
  )
}

export default LazyDateField

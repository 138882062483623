import { createContext } from "react"

import { TBrowserFeature } from "./types"

export interface IBrowserFeatureContext {
  isFeatureSupported: (f: TBrowserFeature) => boolean
  getDomainPath: () => string
}

export default createContext<IBrowserFeatureContext>({
  isFeatureSupported: () => true,
  getDomainPath: () => "",
})

import React from "react"
import { IIconProps } from "./types"
import IconBase from "./IconBase"
import { useCheckBrand } from "./utils/useCheckBrand"

const ChatNotOfferedIcon: React.FC<IIconProps> = ({
  color = "currentColor",
}) => {
  const { isQuesticoBrand } = useCheckBrand()
  return (
    <IconBase>
      {isQuesticoBrand ? (
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M17.2629 11.4498C17.2629 9.65266 16.4529 7.9905 15.0354 6.87677C14.0061 6.06678 12.7658 5.63647 11.4496 5.63647C8.24337 5.63647 5.63623 8.24362 5.63623 11.4498C5.63623 14.656 8.24337 17.2632 11.4496 17.2632C12.2933 17.2632 13.1286 17.0775 13.8964 16.7232L17.7354 17.3728L16.6385 14.0823C17.0435 13.2638 17.2629 12.361 17.2629 11.4498ZM15.8623 15.7697L13.7024 15.4069L13.5167 15.4997C12.8839 15.8204 12.1667 15.9975 11.458 15.9975C8.95211 15.9975 6.91027 13.9557 6.91027 11.4498C6.91027 8.94392 8.95211 6.90208 11.458 6.90208C12.4874 6.90208 13.4576 7.23957 14.2592 7.87237C15.3645 8.74142 16.0057 10.0492 16.0057 11.4498C16.0057 12.2429 15.7948 13.0192 15.4067 13.7026L15.2632 13.9473L15.8623 15.7697Z"
            fill={color}
          />
          <path
            d="M13.5169 9.50879H9.00293V10.7744H13.5169V9.50879Z"
            fill={color}
          />
          <path
            d="M13.5169 11.8716H9.00293V13.1372H13.5169V11.8716Z"
            fill={color}
          />
          <circle cx="12" cy="12" r="9.25" stroke={color} strokeWidth="1.5" />
          <line
            x1="5.1059"
            y1="18.7424"
            x2="18.7423"
            y2="5.10601"
            stroke={color}
            strokeWidth="1.5"
          />
        </svg>
      ) : (
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M15.3444 18.1444C14.3333 18.6889 13.1667 19 12 19C8.11111 19 5 15.8889 5 12C5 8.11111 8.11111 5 12 5C15.8889 5 19 8.11111 19 12C19 13.2444 18.6889 14.4111 18.1444 15.3444"
            stroke={color}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M15.3444 18.1445L19 19L18.1444 15.3445"
            stroke={color}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M4 20L20 4"
            stroke={color}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      )}
    </IconBase>
  )
}

export default ChatNotOfferedIcon

import { LOCAL_STORAGE_KEYS, IListingNumber, IAdvisorStatus } from "../../types"
import {
  FavoriteExpertsStatusesCount,
  IRecentExpertsData,
  IRecentExpertsItem,
  ExpertsCategory,
  TRecommendationsListing,
  TMyExpertListing,
} from "./types"
import { storage } from "../../utils/localStorage"
import { IAdvisorIdOnlineStatus } from "../Experts/types"
import { isStrEqualToTrue } from "../../utils/utils"

/**
 * Normalize data coming from backend.
 * Group and count favorite advisors by availability.
 */
export const countFavoriteExpertsStatuses = (
  experts: IAdvisorIdOnlineStatus[]
): FavoriteExpertsStatusesCount => {
  return experts.reduce((acc: FavoriteExpertsStatusesCount, { status }) => {
    if (status) acc[status] = (acc[status] || 0) + 1

    return acc
  }, {})
}

/**
 * Get current availability status (with amount for FREE status):
 *  - when all Advisors in the Favorites List are OFFLINE, show OFFLINE status;
 *  - when all Advisors in the Favorites List are BUSY, show BUSY status;
 *  - when at least 1 Advisor is FREE, show available, show FREE status;
 *  - when there are BUSY and OFFLINE Advisors in the Favorites List, show BUSY status;
 *
 * For reference: https://adviqo.atlassian.net/browse/MISSION-1265
 */
export const getFavoriteExpertsCommonStatus = (
  countStatuses: FavoriteExpertsStatusesCount
): IAdvisorStatus | undefined => {
  if (!Object.keys(countStatuses).length) return undefined

  const keys = Object.keys(countStatuses) as IAdvisorStatus[]

  if (keys.includes("online")) {
    return "online"
  }

  if (keys.includes("busy")) {
    return "busy"
  }

  return "offline"
}

export const updateRecentExpertsData = (
  userId: string,
  expertId: number
): void => {
  const recentExpertsData =
    storage.getItem<IRecentExpertsData>(
      LOCAL_STORAGE_KEYS.RECENT_EXPERTS_DATA,
      true
    ) || {}

  const recentItems = (userId && recentExpertsData[userId]) || []

  const foundIndex = (recentItems as IRecentExpertsItem[]).findIndex(
    ({ id }) => id === expertId
  )

  const newItem = {
    id: expertId,
    time: Date.now(),
  }

  if (foundIndex === -1) {
    recentItems.unshift(newItem)
  } else {
    recentItems.splice(foundIndex, 1)
    recentItems.unshift(newItem)
  }

  storage.setItem(LOCAL_STORAGE_KEYS.RECENT_EXPERTS_DATA, {
    ...recentExpertsData,
    [userId]: recentItems,
  })
}

export function getCategoryNo(
  listing: TRecommendationsListing
): ExpertsCategory | undefined {
  switch (listing) {
    case "new":
      return ExpertsCategory.New
    case "popular":
      return ExpertsCategory.Popular
    default:
      return undefined
  }
}

export const hasRecommendationsPath = (pathname: string): boolean => {
  return pathname?.includes("/recommendations")
}

export function getExpertsListPageIds(
  expertsIds: IListingNumber[],
  size: number,
  start: number
): string[] {
  return expertsIds.slice(start, start + size)
}

export const getListingName = (listing: TMyExpertListing): string => {
  const listingName = "Expert List Navbar Recommended"
  const nameMapping: { [key in TMyExpertListing]?: string } = {
    forYou: "For You",
    popular: "Popular",
    new: "New",
  }

  return `${listingName} ${nameMapping[listing] || ""}`
}

export function markAnimationAsViewed(): void {
  storage.setItem(LOCAL_STORAGE_KEYS.HAS_SEEN_RECENT_ANIMATION, true)
}

export function hasSeenAnimation(): boolean {
  return isStrEqualToTrue(
    storage.getItem(LOCAL_STORAGE_KEYS.HAS_SEEN_RECENT_ANIMATION)
  )
}

export const getUniqueByKeepLast = <T, K extends keyof T>(
  items: Array<T>,
  keySelector: (item: T) => T[K]
): T[] => {
  return [...new Map(items.map((x) => [keySelector(x), x])).values()]
}

export const getUniqueByKeepFirst = <T, K extends keyof T>(
  items: Array<T>,
  keySelector: (item: T) => T[K]
): T[] => {
  const seen = new Set()
  return items.filter((item) => {
    const k = keySelector(item)
    return seen.has(k) ? false : seen.add(k)
  })
}

/**
 * We have 2 collections of experts:
 *  - recent viewed;
 *  - last contacted.
 * Recent viewed experts stored in FE in local storage as
 * unix timestamp and recent viewed time depends on
 * current user time zone, say CET(MEZ) for DACH and etc.
 *
 * Last contacted date comes from v3 and it is always in CET(MEZ)
 * https://gitlab.com/adviqo/development/qproxy/-/merge_requests/53#note_753379049
 * From v3 re return string representation of date in CET: "time": "2021-08-24 07:38:21"
 *
 * In order to compare experts by time, we get timestamp from
 * recent viewed/last contacted dates (timestamp is always in UTC).
 */
export const getRecentItems = (userId?: string): IRecentExpertsItem[] => {
  const recentExpertsData =
    storage.getItem<IRecentExpertsData>(
      LOCAL_STORAGE_KEYS.RECENT_EXPERTS_DATA,
      true
    ) || {}

  return (userId && recentExpertsData[userId]) || []
}

import React from "react"
import IconBase from "./IconBase"
import { IIconProps } from "./types"
import { useCheckBrand } from "./utils/useCheckBrand"

const CallBackIcon: React.FC<IIconProps> = ({
  color = "currentColor",
  className,
}) => {
  const { isQuesticoBrand } = useCheckBrand()

  return (
    <IconBase className={className}>
      {isQuesticoBrand ? (
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M22 10C22 10 20.8417 8.44917 19.0358 7.71572C17.2274 6.9835 14.8631 8.04844 14.8631 8.04844L15.6332 9.28432C15.6617 9.33028 15.6462 9.39033 15.5979 9.41851C15.5732 9.43322 15.5428 9.4369 15.5144 9.42832L12.0691 8.37502C12.0165 8.35848 11.9874 8.30395 12.0053 8.25248C12.0078 8.24512 12.0109 8.23777 12.0152 8.23164L14.0384 5.04726C14.0675 5.0013 14.1301 4.98598 14.1777 5.01417C14.2056 5.0301 14.2235 5.0589 14.2254 5.09076L14.3022 6.3677C14.3022 6.36709 17.0633 5.40938 19.1621 6.44C21.2633 7.46818 22 10 22 10Z"
            fill={color}
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M17.5377 17.8504L16.7979 18.5912C15.8642 19.5272 14.8205 20 13.7001 20C12.1889 20 10.5588 19.1385 8.8571 17.4352L4.56727 13.1406C3.22473 11.7973 2.39812 10.4934 2.1145 9.26352C1.76942 7.76842 2.20528 6.40027 3.40968 5.19516L4.15002 4.45495C4.62704 3.97765 5.39784 3.86029 5.99383 4.17737C7.11365 4.76976 8.2752 6.71508 8.40263 6.93511C8.6372 7.34246 8.70711 7.76221 8.60618 8.12442C8.53006 8.39974 8.35752 8.62373 8.10661 8.77831C7.76773 9.0621 7.36908 9.4181 7.29691 9.50612C6.8695 10.138 6.91856 10.6232 7.46945 11.1744L10.8221 14.5291C11.377 15.0865 11.854 15.1316 12.4957 14.6978C12.5797 14.6295 12.9332 14.2306 13.2191 13.8915C13.4305 13.5395 13.7779 13.3465 14.2013 13.3465C14.4788 13.3465 14.7753 13.4306 15.0567 13.5914C15.2789 13.7229 17.223 14.8851 17.8151 16.0056C18.1404 16.6188 18.0294 17.359 17.5377 17.8504Z"
            stroke={color}
            strokeWidth="1.5"
            strokeLinecap="square"
          />
        </svg>
      ) : (
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M18.9787 18.5001C19.1787 18.0001 19.0787 16.6001 18.3787 16.1001L15.9787 15.0001C15.2787 14.7001 14.3787 14.9001 14.0787 15.6001C13.9787 15.9001 13.8787 16.0001 13.8787 16.0001C12.8787 16.0001 10.9787 14.6001 10.2787 13.9001C9.57868 13.2001 8.07868 11.3001 8.07868 10.4001C8.07868 10.4001 8.27868 10.3001 8.47868 10.2001C9.27868 9.90008 9.57868 9.00008 9.17868 8.30008L7.97868 6.00008C7.57868 5.30008 6.07868 5.20008 5.57868 5.40008C4.57868 5.70008 3.77868 6.40008 3.37868 7.20008C2.77868 8.30008 2.97868 9.80008 3.37868 11.3001C4.07868 13.4001 5.37868 15.3001 7.17868 17.0001C8.87868 18.7001 10.8787 20.0001 12.9787 20.7001C14.4787 21.0001 15.9787 21.3001 17.1787 20.7001C17.9787 20.2001 18.6787 19.5001 18.9787 18.5001Z"
            stroke={color}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M19.0789 10.4999H15.1789C14.4789 10.4999 13.8789 9.89991 13.8789 9.19991V5.3999"
            stroke={color}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M14.479 9.89999L20.979 3"
            stroke={color}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      )}
    </IconBase>
  )
}

export default CallBackIcon

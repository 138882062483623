import { Renderer, LottieConfig } from "react-lottie-hook"

import { markAnimationAsViewed } from "../../MyExperts/utils"

export const activityConfig: LottieConfig = {
  renderer: Renderer.svg,
  loop: true,
  autoplay: true,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
    progressiveLoad: false,
  },
}

export const spinnerConfig = activityConfig

const emptyStateCommon: LottieConfig = {
  renderer: Renderer.svg,
  loop: false,
  autoplay: true,
}

export const favoritesConfig: LottieConfig = {
  ...emptyStateCommon,
  rendererSettings: {
    preserveAspectRatio: "xMidYMin slice",
    progressiveLoad: true,
  },
}

export const favoritesArrowConfig: LottieConfig = {
  ...emptyStateCommon,
  rendererSettings: {
    preserveAspectRatio: "xMidYMin slice",
    progressiveLoad: true,
  },
}

export const recentsConfig: LottieConfig = {
  ...emptyStateCommon,
  rendererSettings: {
    preserveAspectRatio: "xMinYMin slice",
    progressiveLoad: true,
  },
  eventListeners: {
    complete: markAnimationAsViewed,
  },
}

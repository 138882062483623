import React from "react"
import { IIconProps } from "./types"

const CloseIcon: React.FC<IIconProps> = ({
  color = "currentColor",
  height = "100%",
  width = "100%",
}) => (
  <svg width={width} height={height} viewBox="0 0 23 23">
    <path
      fill={color}
      d="M21.415 0l-10 10-10-10L0 1.414l10 10.001-10 9.999 1.415 1.414 10-10 10 10.001 1.414-1.415-10-9.999 10.001-10z"
    />
  </svg>
)

export default CloseIcon

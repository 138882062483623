import React from "react"
import clsx from "clsx"
import classes from "./Fieldset.module.scss"

interface IFieldsetProps {
  rootClassName?: string
  disabled?: boolean
  testId?: string
  children: React.ReactNode
}

const Fieldset: React.FC<IFieldsetProps> = ({
  children,
  rootClassName,
  disabled,
  testId,
}) => {
  const className = clsx(
    classes.fieldset,
    { [classes.disabled]: disabled },
    rootClassName
  )

  return (
    <fieldset className={className} data-testid={testId}>
      {children}
    </fieldset>
  )
}

export default Fieldset

import { LOCAL_STORAGE_KEYS } from "../../types"
import sentryUtils from "../sentryUtils"

type TGetItemReturn<T> = T extends undefined ? string : T

function reportToSentry(e: Error) {
  if (
    ["localStorage", "The operation is insecure"].find((curr) =>
      e.message.includes(curr)
    )
  ) {
    sentryUtils.report({
      message: `[LocalStorageError]: ${e.message}`,
    })
    return
  }

  sentryUtils.report({
    error: e,
  })
}

function cleanUp(key: LOCAL_STORAGE_KEYS): void {
  if (typeof window !== "undefined") {
    try {
      window.localStorage.removeItem(key)
    } catch (e) {
      if (e instanceof Error) reportToSentry(e)
    }
  }
}

function getItem<P = undefined>(
  key: LOCAL_STORAGE_KEYS,
  asObject?: boolean
): TGetItemReturn<P> | null {
  if (typeof window === "undefined") return null

  try {
    const rawSaved = window.localStorage.getItem(key)

    if (!rawSaved) return null

    if (asObject) return JSON.parse(rawSaved) as TGetItemReturn<P>

    return rawSaved as TGetItemReturn<P>
  } catch (e) {
    if (e instanceof Error) reportToSentry(e)

    return null
  }
}

function setItem<T>(key: LOCAL_STORAGE_KEYS, value: T): void {
  const toSave = typeof value === "string" ? value : JSON.stringify(value)

  try {
    window.localStorage.setItem(key, toSave)
  } catch (e) {
    if (e instanceof Error) reportToSentry(e)
  }
}

export const storage = {
  setItem,
  getItem,
  cleanUp,
}

import React from "react"
import { IIconProps } from "./types"

const ErrorCircleSmallIcon: React.FC<IIconProps> = ({
  color = "currentColor",
  height = "100%",
  width = "100%",
}) => (
  <svg width={width} height={height} viewBox="0 0 18 18">
    <path
      fill={color}
      d="M9 0C4.032 0 0 4.032 0 9s4.032 9 9 9 9-4.032 9-9-4.032-9-9-9zm.9 13.5H8.1v-1.8h1.8v1.8zm0-3.6H8.1V4.5h1.8v5.4z"
    />
  </svg>
)

export default ErrorCircleSmallIcon

import React from "react"
import clsx from "clsx"

import { Box, Button, Typography } from "../MaterialUI"
import classes from "./ToastMessage.module.scss"

interface IToastMessageProps {
  hintMessage: string
  buttonTitle: string
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
  rootClassName?: string
  testID?: string
}

const ToastMessage: React.FC<IToastMessageProps> = ({
  hintMessage,
  buttonTitle,
  onClick,
  rootClassName,
  testID,
}) => {
  return (
    <Box className={clsx(classes.root, rootClassName)}>
      <Typography component="span" className={classes.hint}>
        {hintMessage}
      </Typography>
      <Button
        onClick={onClick}
        color="primary"
        className={classes.button}
        data-testid={testID}
      >
        {buttonTitle}
      </Button>
    </Box>
  )
}

export default ToastMessage

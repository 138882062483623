import clsx from "clsx"
import React from "react"
import { ButtonBase, Typography } from "../MaterialUI"
import classes from "./PseudoLink.module.scss"

interface IPseudoLinkProps {
  onClick: React.MouseEventHandler
  rootClassName?: string
  labelClassName?: string
  disabled?: boolean
  testId?: string
  children: React.ReactNode
}

const PseudoLink: React.FC<IPseudoLinkProps> = ({
  children,
  onClick,
  labelClassName,
  rootClassName,
  disabled,
  testId,
}) => {
  const baseClassName = clsx(classes.root, rootClassName)
  const textClassName = clsx(classes.pseudoLink, labelClassName)

  return (
    <ButtonBase
      onClick={onClick}
      className={baseClassName}
      disableRipple
      disableTouchRipple
      disabled={disabled}
      data-testid={testId}
    >
      <Typography component="span" className={textClassName}>
        {children}
      </Typography>
    </ButtonBase>
  )
}

export default PseudoLink

"use client"

import React from "react"
import { CustomArrowProps } from "react-slick"

import ArrowIcon from "../QuesticoIcon/ArrowIcon"
import clsx from "clsx"
import classes from "./CarouselArrow.module.scss"

interface ICarouselArrowProps extends CustomArrowProps {
  rootClassName?: string
  action: "next" | "prev"
}

export const CarouselArrow: React.FC<ICarouselArrowProps> = (props) => {
  const { onClick, action, rootClassName } = props

  const buttonClasses = clsx(
    classes.button,
    action,
    {
      [classes.prev]: action === "prev",
      [classes.next]: action === "next",
    },
    rootClassName
  )

  return (
    <button type="button" onClick={onClick} className={buttonClasses}>
      <ArrowIcon height={14} width={18} />
    </button>
  )
}

export default CarouselArrow

import React from "react"
import { IIconProps } from "./types"

const SuccessCircleIcon: React.FC<IIconProps> = ({
  color = "currentColor",
  height = "100%",
  width = "100%",
}) => (
  <svg
    width={width}
    height={height}
    data-testid="successIcon"
    viewBox="0 0 60 60"
  >
    <g fill={color}>
      <path
        d="M19.345 24.854L23.399 24.854 23.399 38.168 19.345 38.168z"
        transform="rotate(-45.08 21.372 31.51)"
      />
      <path
        d="M22.192 27.721L48.365 27.721 48.365 31.775 22.192 31.775z"
        transform="rotate(-45.47 35.279 29.748)"
      />
      <path d="M30 60C13.431 60 0 46.569 0 30 0 13.431 13.431 0 30 0c16.569 0 30 13.431 30 30-.018 16.561-13.439 29.982-30 30zm0-55.91C15.69 4.09 4.09 15.69 4.09 30S15.69 55.91 30 55.91 55.91 44.31 55.91 30C55.894 15.697 44.304 4.107 30 4.09z" />
    </g>
  </svg>
)

export default SuccessCircleIcon

import React, { useState } from "react"
import clsx from "clsx"
import {
  IconButton,
  Tooltip,
  ClickAwayListener,
  Typography,
  useTheme,
  useMediaQuery,
} from "../MaterialUI"
import classes from "./CustomTooltip.module.scss"

interface ITooltipProps {
  tooltipText: string | React.ReactNode
  textIconNode?: React.ReactNode
  iconNode: React.ReactNode
  iconButtonClassName?: string
  tooltipClassName?: string
  testId?: string
  /**
   * We support only two placements so far.
   * Feel free to add more
   * - `"top-end"` is default
   * - `"bottom"` has no arrow
   */
  placement?: "bottom" | "top-end"
}

const CustomTooltip: React.FC<ITooltipProps> = ({
  tooltipText,
  textIconNode,
  iconNode,
  iconButtonClassName,
  tooltipClassName: _tooltipClassName,
  testId,
  placement = "top-end",
}) => {
  const [tooltipVisible, setTooltipVisible] = useState(false)
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("md"))

  const closeTooltip = () => setTooltipVisible(false)
  const toggleTooltip = () => setTooltipVisible((prev) => !prev)
  const showTooltip = () => setTooltipVisible(true)
  const iconProps = isMobile
    ? {
        onClick: toggleTooltip,
      }
    : {
        onMouseOver: showTooltip,
        onMouseOut: closeTooltip,
      }
  const tooltipClassName = clsx(
    classes.tooltip,
    {
      [classes.tooltipWithIcon]: !!textIconNode,
    },
    _tooltipClassName
  )

  const iconClassName = clsx(classes.iconButton, iconButtonClassName)

  return (
    <ClickAwayListener onClickAway={closeTooltip}>
      <Tooltip
        PopperProps={{
          keepMounted: false,
          modifiers: [
            {
              name: "flip",
              enabled: false,
            },
          ],
        }}
        title={
          <>
            <Typography className={classes.tooltipText}>
              {tooltipText}
            </Typography>
            {textIconNode}
            {placement === "top-end" && (
              <div data-popper-arrow className={classes.tooltipArrow} />
            )}
          </>
        }
        classes={{
          tooltip: tooltipClassName,
          tooltipPlacementBottom: classes.tooltipPlacementBottom,
        }}
        placement={placement}
        open={tooltipVisible}
      >
        <IconButton
          data-testid={testId}
          className={iconClassName}
          edge="end"
          size="small"
          {...iconProps}
        >
          {iconNode}
        </IconButton>
      </Tooltip>
    </ClickAwayListener>
  )
}

export default CustomTooltip

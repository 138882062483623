"use client"

import React, { forwardRef } from "react"
import NextLink from "next/link"
import clsx from "clsx"
import { UrlObject } from "url"

import { Link as MaterialLink } from "../MaterialUI"

import { IAdviqoLinkProps } from "../../../types"
import { useBrowserFeatures } from "../../../hooks/browserFeatures"
import classes from "./AdviqoLink.module.scss"

/**
 * @description Tailor the following test to your environment. This example assumes that any internal link will start with exactly one slash, and that anything else is external.
 */
function isNextLink(to: string | UrlObject): boolean {
  if (typeof to === "string") return /^\/(?!\/)/.test(to)

  return true
}

const AdviqoLink = forwardRef<HTMLAnchorElement, IAdviqoLinkProps>(
  function AdviqoLink(
    { children, to, className, as = to, onClick, ...props },
    ref
  ) {
    const linkClassName = clsx(classes.link, className)
    const { getDomainPath } = useBrowserFeatures()

    if (isNextLink(to)) {
      return (
        <NextLink
          legacyBehavior
          href={to}
          as={typeof as === "string" ? `${getDomainPath()}${as}` : as}
          passHref
        >
          <MaterialLink
            ref={ref}
            color="inherit"
            className={linkClassName}
            onClick={onClick}
            target="_self"
            {...props}
          >
            {children}
          </MaterialLink>
        </NextLink>
      )
    }

    return (
      <MaterialLink
        ref={ref}
        href={to as string}
        color="inherit"
        className={linkClassName}
        onClick={onClick}
        target="_blank"
        {...props}
      >
        {children}
      </MaterialLink>
    )
  }
)

export default AdviqoLink

import React from "react"
import IconBase from "./IconBase"
import { IIconProps } from "./types"
import { useCheckBrand } from "./utils/useCheckBrand"

const CallNotOfferedIcon: React.FC<IIconProps> = ({
  color = "currentColor",
}) => {
  const { isQuesticoBrand } = useCheckBrand()

  return (
    <IconBase>
      {isQuesticoBrand ? (
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M11.0121 10.194C11.3067 10.0029 11.5058 9.72422 11.5934 9.39775C11.7128 8.97573 11.6332 8.50594 11.3863 8.07596C11.2191 7.78931 10.3512 6.34012 9.40366 5.83847C8.73481 5.48016 7.89873 5.60756 7.36523 6.14902L6.83174 6.68251C5.82049 7.70172 5.4383 8.91999 5.73291 10.202C5.96383 11.2053 6.61675 12.2484 7.66781 13.3153L10.7732 16.4446C12.1189 17.7983 13.4407 18.491 14.7067 18.491C15.6861 18.491 16.5859 18.0849 17.3742 17.2887L17.9077 16.7472C18.4492 16.2058 18.5686 15.3856 18.2103 14.7088C17.7325 13.8011 16.3868 12.957 15.9808 12.7102C15.2084 12.2643 14.2927 12.4315 13.8707 13.0924C13.7035 13.2915 13.5124 13.5144 13.4964 13.5383C13.2735 13.6896 13.2496 13.6975 13.0426 13.4905L10.614 11.046C10.4069 10.839 10.4149 10.7992 10.5184 10.6399C10.5901 10.5603 10.813 10.3612 11.0121 10.194ZM12.1985 14.3186C12.8037 14.9317 13.4725 14.9954 14.2051 14.4858C14.3405 14.3823 14.6749 13.9842 14.8103 13.817L14.866 13.7374C14.9297 13.6338 15.1447 13.594 15.3836 13.7294C16.0922 14.1514 16.9124 14.7805 17.1592 15.2502C17.2707 15.4652 17.2388 15.72 17.0637 15.8952L16.5302 16.4367C15.1686 17.8062 13.5203 17.4957 11.6252 15.5926L8.51982 12.4633C7.62801 11.5636 7.07859 10.7116 6.90341 9.93124C6.69639 9.0474 6.95119 8.2591 7.68374 7.52654L8.21724 6.98509C8.32075 6.88157 8.46408 6.8338 8.60741 6.8338C8.69499 6.8338 8.77462 6.84972 8.85424 6.88953C9.36385 7.16026 10.0327 8.09985 10.3592 8.66519C10.4468 8.81648 10.4786 8.96777 10.4468 9.07925C10.4388 9.1111 10.4149 9.15887 10.3512 9.20665L10.2795 9.25442C10.2238 9.3022 9.73809 9.70829 9.57884 9.90735C9.10905 10.6081 9.17275 11.269 9.77791 11.8741L12.1985 14.3186Z"
            fill={color}
          />
          <circle cx="12" cy="12" r="9.25" stroke={color} strokeWidth="1.5" />
          <line
            x1="5.1059"
            y1="18.7424"
            x2="18.7423"
            y2="5.10601"
            stroke={color}
            strokeWidth="1.5"
          />
        </svg>
      ) : (
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M18.8908 16.6766C19.0865 16.2912 19.0865 14.9208 18.3177 14.5354L16.221 13.5647C15.6479 13.1793 14.8931 13.5647 14.5017 14.15C14.306 14.3498 14.306 14.5354 14.306 14.5354C13.3555 14.5354 11.8319 13.1793 11.2588 12.5941C10.6857 12.0088 9.35782 10.253 9.35782 9.48221C9.35782 9.48221 9.55351 9.48222 9.73523 9.28237C10.3083 8.89696 10.6857 8.11184 10.3083 7.52658L9.35782 5.58526C8.98042 5.00001 7.63854 5 7.26113 5C6.31064 5.19984 5.73754 5.97067 5.36014 6.55592C4.78704 7.52659 4.98273 8.89695 5.36014 10.253C5.93323 12.1944 7.07942 13.9501 8.60301 15.3205C10.1266 16.8764 11.8459 18.047 13.5652 18.6322C14.8931 19.0176 16.235 19.2175 17.1855 18.6322C17.9542 18.2468 18.5134 17.6615 18.9047 16.6909L18.8908 16.6766Z"
            stroke={color}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M4 20L20 4"
            stroke={color}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      )}
    </IconBase>
  )
}

export default CallNotOfferedIcon

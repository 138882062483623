import React from "react"
import { useViewport } from "../../../hooks/viewport"
import { Typography, Box, Grid } from "../MaterialUI"
import AdviqoButton from "../AdviqoButton"
import Logo from "../Logo"
import Modal from "./ModalBase"
import { IConfirmationModalProps } from "./types"
import classes from "./ConfirmationModal.module.scss"

const ConfirmationModal: React.FC<IConfirmationModalProps> = ({
  opened = false,
  onCancel,
  onConfirm,
  confirmButtonDisabled = false,
  cancelButtonDisabled = false,
  cancelButtonLabel,
  confirmButtonLabel,
  confirmationText,
  confirmationTitle,
  wrapperClassName,
  isInvertedActions = false,
}) => {
  const { isViewportDown } = useViewport()
  const isMobile = isViewportDown("sm")
  const logoSize = isMobile
    ? { width: 30, height: 23 }
    : { width: 34, height: 26 }

  return (
    <Modal
      opened={opened}
      closeModal={onCancel}
      wrapperClassName={wrapperClassName}
    >
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        data-testid="confirmation-modal"
      >
        <Logo
          withLink={false}
          wrapperClassName={classes.logo}
          size={logoSize}
        />
        {confirmationTitle && (
          <Box>
            <Typography
              component="h3"
              className={classes.mainTitle}
              data-testid="confirmation-title"
            >
              {confirmationTitle}
            </Typography>
          </Box>
        )}
        <Box>
          <Typography
            color="secondary"
            className={classes.mainText}
            data-testid="confirmation-text"
          >
            {confirmationText}
          </Typography>
        </Box>
        {isInvertedActions ? (
          <Box className={classes.buttons}>
            <AdviqoButton
              onClick={onCancel}
              buttonClassName={classes.button}
              label={cancelButtonLabel}
              testId="cancel"
              disabled={cancelButtonDisabled}
            />
            <AdviqoButton
              onClick={onConfirm}
              buttonClassName={classes.button}
              label={confirmButtonLabel}
              variant="outlined"
              testId="confirm"
              disabled={confirmButtonDisabled}
            />
          </Box>
        ) : (
          <Box className={classes.buttons}>
            <AdviqoButton
              onClick={onConfirm}
              buttonClassName={classes.button}
              label={confirmButtonLabel}
              testId="confirm"
              disabled={confirmButtonDisabled}
            />
            <AdviqoButton
              onClick={onCancel}
              buttonClassName={classes.button}
              label={cancelButtonLabel}
              variant="outlined"
              testId="cancel"
              disabled={cancelButtonDisabled}
            />
          </Box>
        )}
      </Grid>
    </Modal>
  )
}

export default ConfirmationModal

import React, { forwardRef, useState, useCallback } from "react"
import Field from "./Field"
import { TFieldProps } from "./types"

type IOnlyLettersFieldProps = TFieldProps & {
  /**
   * `false` by default. Allows underscore as a symbol in a field
   */
  allowUnderscore?: boolean
}

const OnlyLettersField: React.ForwardRefRenderFunction<
  HTMLInputElement,
  IOnlyLettersFieldProps
> = ({ defaultValue, onChange, allowUnderscore = false, ...rest }, ref) => {
  const [fieldValue, setFieldValue] = useState(defaultValue)

  const onChangeHandler = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target

      /**
       * Amend Regular Expression to allow German umlauts,
       * French accents and other valid European letters.
       * https://stackoverflow.com/questions/19001140/amend-regular-expression-to-allow-german-umlauts-french-accents-and-other-valid/22152471
       * Filter special characters on the beginning of a string.
       * Prevent enter more that 2 special characters consistently.
       */
      let filteredValue: string

      if (allowUnderscore) {
        filteredValue = value.replace(
          /(^[._-\s])|([._-\s]{2})|([^_a-zA-Z\u00C0-\u00D6\u00D8-\u00F6\u00F8-\u00FF\-.\s])/g,
          ""
        )
      } else {
        filteredValue = value.replace(
          /(^[.-\s])|([.-\s]{2})|([^a-zA-Z\u00C0-\u00D6\u00D8-\u00F6\u00F8-\u00FF\-.\s])/g,
          ""
        )
      }

      e.target.value = filteredValue
      setFieldValue(filteredValue)

      if (onChange !== undefined) onChange(e)
    },
    [allowUnderscore, onChange]
  )

  return (
    <Field {...rest} ref={ref} value={fieldValue} onChange={onChangeHandler} />
  )
}

export default forwardRef<HTMLInputElement, IOnlyLettersFieldProps>(
  OnlyLettersField
)

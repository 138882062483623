import React from "react"
import IconBase from "./IconBase"
import { IIconProps } from "./types"
import { useCheckBrand } from "./utils/useCheckBrand"

const ChatIcon: React.FC<IIconProps> = ({
  color = "currentColor",
  className,
}) => {
  const { isQuesticoBrand } = useCheckBrand()

  return (
    <IconBase className={className}>
      {isQuesticoBrand ? (
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M20.5 20.5L19.8113 16.4695C20.5889 15.1129 21 13.5709 21 12.0008C21 9.13034 19.6495 6.56904 17.5511 4.91997C16.0211 3.71773 14.0931 3 12.0013 3C7.03747 3 3 7.03697 3 12.0008C3 16.963 7.03747 21 12.0013 21C13.4156 21 14.8239 20.6617 16.083 20.0214L20.5 20.5Z"
            stroke={color}
            strokeWidth="1.5"
            strokeLinecap="square"
          />
          <path
            d="M9.31152 9.56283H14.9563"
            stroke={color}
            strokeWidth="1.5"
            strokeLinecap="square"
          />
          <path
            d="M9.31152 13.671H14.9563"
            stroke={color}
            strokeWidth="1.5"
            strokeLinecap="square"
          />
        </svg>
      ) : (
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M16.3 19.9C15 20.6 13.5 21 12 21C7 21 3 17 3 12C3 7 7 3 12 3C17 3 21 7 21 12C21 13.6 20.6 15.1 19.9 16.3"
            stroke={color}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M16.3 19.9L21 21L19.9 16.3"
            stroke={color}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M16.0999 12.8C15.6581 12.8 15.2999 12.4418 15.2999 12C15.2999 11.5581 15.6581 11.2 16.0999 11.2C16.5417 11.2 16.8999 11.5581 16.8999 12C16.8999 12.4418 16.5417 12.8 16.0999 12.8Z"
            fill={color}
          />
          <path
            d="M11.9998 12.8C11.558 12.8 11.1998 12.4418 11.1998 12C11.1998 11.5581 11.558 11.2 11.9998 11.2C12.4416 11.2 12.7998 11.5581 12.7998 12C12.7998 12.4418 12.4416 12.8 11.9998 12.8Z"
            fill={color}
          />
          <path
            d="M7.9002 12.8C7.45837 12.8 7.1002 12.4418 7.1002 12C7.1002 11.5581 7.45837 11.2 7.9002 11.2C8.34203 11.2 8.7002 11.5581 8.7002 12C8.7002 12.4418 8.34203 12.8 7.9002 12.8Z"
            fill={color}
          />
        </svg>
      )}
    </IconBase>
  )
}

export default ChatIcon

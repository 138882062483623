import React from "react"
import clsx from "clsx"

import { Box, Typography, useTheme } from "../MaterialUI"
import Paper from "../Paper"
import ErrorCircleIcon from "../QuesticoIcon/ErrorCircleIcon"
import classes from "./ErrorSection.module.scss"

interface IErrorSection {
  icon?: React.ReactNode
  title?: string
  body?: string
  /**
   * bodyNode - optional node.
   * If it is not empty, it will be rendered. Otherwise, body field (string) will be rendered.
   */
  bodyNode?: React.ReactNode
  renderActions?: ({
    btnClassName,
  }: {
    btnClassName: string
  }) => React.ReactNode
  rootClassName?: string
  paperBodyClassName?: string
  iconClassName?: string
  buttonClassName?: string
  titleClassName?: string
  bodyClassName?: string
  testId?: string
  children?: React.ReactNode
}

const ErrorSection: React.FC<IErrorSection> = ({
  icon,
  title,
  body,
  bodyNode,
  renderActions,
  rootClassName,
  paperBodyClassName,
  iconClassName,
  buttonClassName,
  titleClassName,
  bodyClassName,
  testId,
  children,
}) => {
  const theme = useTheme()
  const bodyResult = bodyNode || body

  return (
    <Paper
      rootClassName={rootClassName}
      testId={testId || "error-section"}
      bodyClassName={clsx(classes.paperBody, paperBodyClassName)}
    >
      <Box className={clsx(classes.icon, iconClassName)}>
        {icon || <ErrorCircleIcon color={theme.palette.error.main} />}
      </Box>

      {title && (
        <Typography className={clsx(classes.title, titleClassName)}>
          {title}
        </Typography>
      )}

      {bodyResult && (
        <Typography
          className={clsx(classes.errorText, bodyClassName)}
          component="div"
        >
          {bodyResult}
        </Typography>
      )}

      {renderActions &&
        renderActions({ btnClassName: clsx(classes.button, buttonClassName) })}

      {children}
    </Paper>
  )
}

export default ErrorSection

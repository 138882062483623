import React from "react"
import { TFieldProps } from "./types"
import Field from "./Field"

const ALLOWED_KEY_CODES = [8, 9, 13, 37, 39]

/**
 * TODO: let's refactor TFieldProps interface with
 * our global types and interfaces refactoring scope.
 * The problem we faced here is that we can pass same props
 * for inputProps and ...rest that can conflict with each other
 * at some point.
 * For example onKeyDown, which can be props itself and prop inside inputProps.
 * Link to thread:
 * - https://gitlab.com/adviqo/development/advii/-/merge_requests/1737#note_744647389
 */
const NumberField: React.ForwardRefRenderFunction<
  HTMLInputElement,
  TFieldProps
> = ({ inputProps, ...rest }, ref) => {
  // allow numbers, Enter, BackSpace, Tab, <- and -> arrows
  const handleOnKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (
      !ALLOWED_KEY_CODES.includes(e.keyCode) &&
      // not a keypad digit or not a numpad digit
      !(e.keyCode >= 48 && e.keyCode <= 57) &&
      !(e.keyCode >= 96 && e.keyCode <= 105)
    ) {
      if (e.preventDefault) {
        e.preventDefault()
        e.stopPropagation()
      } else {
        // for IE
        return false
      }
    }
    return true
  }

  return (
    <Field
      {...rest}
      inputProps={{
        ...inputProps,
        // bring up a keypad of large numeric keys on mobile devices
        pattern: "[0-9]*",
        inputMode: "numeric",
      }}
      onKeyDown={handleOnKeyDown}
      ref={ref}
    />
  )
}

export default React.forwardRef<HTMLInputElement, TFieldProps>(NumberField)

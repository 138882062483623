import React, { MouseEventHandler } from "react"
import AdviqoButton from "../AdviqoButton"

interface RegisterButtonProps {
  onClick: MouseEventHandler
  className?: string
  label?: string
}

const RegisterButton: React.FC<RegisterButtonProps> = ({
  onClick,
  className,
  label = "Registrieren",
}) => (
  <AdviqoButton
    color="secondary"
    labelClassName={className}
    onClick={onClick}
    testId="navbar-registration"
    label={label}
    fullWidth
  />
)

export default RegisterButton

import { getBrowserDetails } from "./helpers"

/** @see https://caniuse.com/?search=webp */
export const isWEBPSupported = (uaString: string | undefined | null) => {
  // majority of browsers support webp
  if (!uaString) return true

  const { browser, os }: UAParser.IResult = getBrowserDetails(uaString)!

  const isIESupported = (browser: UAParser.IBrowser) =>
    browser.version && parseFloat(browser.version) > 11

  const isFirefoxSupported = (browser: UAParser.IBrowser) =>
    browser.version && parseFloat(browser.version) >= 65

  // safari supports webp on Big Sur
  const isSafariSupported = (browser: UAParser.IBrowser, os: UAParser.IOS) =>
    browser.version &&
    parseFloat(browser.version) >= 14 &&
    os.version &&
    parseFloat(os.version) >= 11

  let supported

  switch (browser.name) {
    case "IE":
      supported = isIESupported(browser)
      break
    case "Safari":
      supported = isSafariSupported(browser, os)
      break
    case "Firefox":
      supported = isFirefoxSupported(browser)
      break
    default:
      supported = true
  }

  return Boolean(supported)
}

export const isMobile = (uaString: string | undefined | null) => {
  if (!uaString) return true

  const { device }: UAParser.IResult = getBrowserDetails(uaString)!
  const deviceType = device.type

  return deviceType === "tablet" || deviceType === "mobile"
}

/** @see https://docs.usercentrics.com/#/browser-ui?id=step-1-integrate-the-javascript-tag */
export const isLegacyUsercentrics = (uaString: string | undefined | null) => {
  // assume worst scenario
  if (!uaString) return true

  const { browser }: UAParser.IResult = getBrowserDetails(uaString)!

  if (browser.name === "IE" && browser.version) {
    return parseFloat(browser.version) <= 11
  }

  return false
}

import React from "react"
import dynamic from "next/dynamic"
import { Props as InfiniteScrollProps } from "react-infinite-scroll-component"

const InfiniteScroll = dynamic(() => import("react-infinite-scroll-component"))

const LazyInfiniteScroll: React.FC<InfiniteScrollProps> = (props) => {
  return <InfiniteScroll {...props} />
}

export default LazyInfiniteScroll

"use client"

import React, { ReactNode } from "react"
import Slider from "react-slick"

import CarouselArrow from "./CarouselArrow"
import { staticSettings } from "./settings"

interface ICarouselProps {
  arrowsClassName?: string
  settings?: Record<string, unknown>
  itemsLength: number
  children: ReactNode
}

const Carousel: React.FC<ICarouselProps> = ({
  arrowsClassName,
  settings: _settings,
  itemsLength,
  children,
}) => {
  const settings = {
    ...staticSettings,
    ..._settings,
    nextArrow: <CarouselArrow action="next" rootClassName={arrowsClassName} />,
    prevArrow: <CarouselArrow action="prev" rootClassName={arrowsClassName} />,
  }

  if (itemsLength === 0) {
    return null
  }

  return itemsLength > 1 ? (
    <>
      {/* @ts-ignore */}
      <Slider {...settings}>{children}</Slider>
    </>
  ) : (
    <>{children}</>
  )
}

export default Carousel

import React from "react"
import clsx from "clsx"
import { TextField } from "../MaterialUI"
import { TFieldProps } from "./types"
import classes from "./Field.module.scss"

const Field: React.ForwardRefRenderFunction<HTMLInputElement, TFieldProps> = (
  {
    className,
    testId = undefined,
    fullWidth = true,
    required = true,
    InputProps,
    error,
    ...rest
  },
  ref
) => {
  const fieldClassName = clsx(
    classes.field,
    { [classes.fieldError]: !!error },
    className
  )

  return (
    <TextField
      {...rest}
      inputRef={ref}
      error={error}
      fullWidth={fullWidth}
      variant="outlined"
      required={required}
      data-testid={testId}
      InputLabelProps={{
        shrink: true,
        className: classes.label,
      }}
      InputProps={{
        classes: {
          input: classes.input,
        },
        ...InputProps,
      }}
      className={fieldClassName}
    />
  )
}

export default React.forwardRef<HTMLInputElement, TFieldProps>(Field)

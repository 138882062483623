import React from "react"
import clsx from "clsx"
import AdviqoLink from "../AdviqoLink"
import { Typography, IconButton } from "../MaterialUI"
import GenericAdviqoButton from "./GenericAdviqoButton"
import { IAdviqoLinkProps } from "../../../types"
import classes from "./AdviqoButton.module.scss"

type TButtonSize = "small" | "medium" | "large"

type TAdviqoButtonVariant = "outlined" | "contained" | "text"

type TColor = "primary" | "secondary" | "custom"

export interface IAdviqoButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    Partial<Pick<IAdviqoLinkProps, "to" | "target">> {
  buttonClassName?: string
  label?: string | JSX.Element | React.ReactNode
  labelClassName?: string
  iconLabelClassName?: string
  fullWidth?: boolean
  round?: boolean
  disabled?: boolean
  variant?: TAdviqoButtonVariant
  icon?: JSX.Element
  /** Will be applied to the iconWrapper if both icon and label are provided */
  iconClassName?: string
  size?: TButtonSize
  labelCentered?: boolean
  testId?: string
  disableTouchRipple?: boolean
  color?: TColor
  isEndIcon?: boolean
  name?: string
}

const AdviqoButton: React.FC<IAdviqoButtonProps> = ({
  label = undefined,
  color = "primary",
  fullWidth = false,
  icon = undefined,
  iconClassName = undefined,
  buttonClassName = undefined,
  round = false,
  size = "medium",
  variant = "contained",
  labelClassName = undefined,
  iconLabelClassName = undefined,
  disabled = false,
  onClick = undefined,
  testId = undefined,
  type = "button",
  disableTouchRipple = undefined,
  target = undefined,
  isEndIcon = false,
  to,
  name,
  ...rest
}) => {
  const rootClassName = clsx(
    classes.buttonBase,
    {
      [classes.outlinedPrimary]: color === "primary" && variant === "outlined",
      [classes.colorSecondary]: color === "secondary",
    },
    buttonClassName
  )
  const rootRoundClassName = clsx(
    classes.buttonBase,
    classes.buttonRound,
    {
      [classes.buttonSecondary]: color === "secondary",
    },
    buttonClassName
  )
  let iconChildren = null

  if (icon && !round) {
    iconChildren = (
      <span className={clsx(classes.iconWrapper, iconClassName)}>{icon}</span>
    )
  } else if (icon && round) {
    iconChildren = icon
  }

  const muiColor = color === "custom" ? undefined : color

  const ariaLabelData = rest["aria-label"]
    ? {
        "aria-label": rest["aria-label"],
      }
    : {}

  if (round) {
    return (
      <IconButton
        color={muiColor}
        classes={{
          root: clsx(
            rootRoundClassName,
            /* classes.iconLabel, */ iconLabelClassName
          ),
        }}
        data-testid={testId}
        disabled={disabled}
        onClick={onClick}
        type={type}
        {...ariaLabelData}
        size="large"
        name={name}
      >
        {iconChildren}
      </IconButton>
    )
  }

  const commonProps = {
    disableTouchRipple,
    variant,
    size,
    color: muiColor,
    disabled,
    onClick,
    fullWidth,
    "data-testid": testId,
    classes: {
      root: clsx(classes.buttonLabel, rootClassName),
    },
    type,
    name,
    ...ariaLabelData,
  }

  const child = label && (
    <Typography
      component="span"
      className={clsx(classes.label, labelClassName)}
    >
      {label}
    </Typography>
  )

  if (to) {
    const additionalProps = target ? { target } : {}

    return (
      <GenericAdviqoButton
        {...commonProps}
        component={AdviqoLink}
        to={to}
        {...additionalProps}
      >
        {child || ""}
      </GenericAdviqoButton>
    )
  }

  return (
    <GenericAdviqoButton {...commonProps}>
      {!isEndIcon && iconChildren}
      {child || ""}
      {isEndIcon && iconChildren}
    </GenericAdviqoButton>
  )
}

export default AdviqoButton

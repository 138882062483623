import React from "react"
import { IIconProps } from "./types"

const InfoIcon: React.FC<IIconProps> = ({
  color = "currentColor",
  height = "19",
  width = "19",
}) => (
  <svg width={width} height={height} viewBox="0 0 19 19">
    <g fill={color}>
      <path d="M8.5 6.817L10 6.817 10 4.83 8.5 4.83zM8.5 14.156L10 14.156 10 7.864 8.5 7.864z" />
      <path d="M9.25 17C4.977 17 1.5 13.523 1.5 9.25S4.977 1.5 9.25 1.5 17 4.977 17 9.25 13.523 17 9.25 17m0-17C4.149 0 0 4.149 0 9.25s4.149 9.25 9.25 9.25 9.25-4.149 9.25-9.25S14.351 0 9.25 0" />
    </g>
  </svg>
)

export default InfoIcon

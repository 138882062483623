import React from "react"
import IconBase from "./IconBase"
import { IIconProps } from "./types"
import { useCheckBrand } from "./utils/useCheckBrand"
import classes from "./HeartIcon.module.scss"

const HeartIcon: React.FC<IIconProps> = ({ color = "currentColor" }) => {
  const { isQuesticoBrand } = useCheckBrand()
  return (
    <IconBase className={classes.root}>
      {isQuesticoBrand ? (
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M20.544 5.46228C18.5943 3.51257 15.4423 3.51257 13.4926 5.46228L11.979 6.97594L10.5089 5.50588C8.56544 3.56241 5.4073 3.56241 3.4576 5.50588C1.51413 7.45558 1.51413 10.6075 3.4576 12.5572L4.92766 14.0273L11.979 21.0786L20.5377 12.5198C22.4874 10.5701 22.4874 7.4182 20.5377 5.4685L20.544 5.46228Z"
            stroke={color}
            strokeWidth="1.5"
            strokeMiterlimit="10"
            strokeLinecap="square"
          />
        </svg>
      ) : (
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M16.1 3C14.4 3 12.9 3.9 12 5.3C11.1 3.9 9.6 3 7.9 3C5.2 3 3 5.3 3 8.1C3 14.1 11.2 21 12 21C12.8 21 21 14.1 21 8.1C21 5.3 18.8 3 16.1 3Z"
            stroke={color}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      )}
    </IconBase>
  )
}

export default HeartIcon

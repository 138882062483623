import React from "react"
import clsx from "clsx"

import { CircularProgress, Box, MUIPaper } from "../MaterialUI"
import PaperHeader, { IPaperHeaderProps } from "./PaperHeader"
import classes from "./Paper.module.scss"

export interface IPaperProps
  extends Partial<
    Omit<IPaperHeaderProps, "headerRootClassName" | "headerTextClassName">
  > {
  title?: React.ReactElement | string | null
  rootClassName?: string
  loading?: boolean
  loaderSize?: number
  anchor?: string
  headerTestId?: string
  headerClassName?: string
  bodyClassName?: string
  /** custom header could help you in case of complex, non-ordinary layouts */
  customHeader?: React.ReactElement
  isSticky?: boolean
  isHJSuppress?: boolean
  children?: React.ReactNode
}

const Paper: React.FC<IPaperProps> = ({
  children,
  title = undefined,
  withError = false,
  loading = false,
  loaderSize = undefined,
  rootClassName = undefined,
  anchor = "",
  testId = undefined,
  interactive = undefined,
  backUrl = undefined,
  withDivider = true,
  bigHeader = false,
  bodyClassName = undefined,
  headerClassName,
  headerTestId = undefined,
  customHeader = undefined,
  CustomLeftSlot,
  CustomRightSlot,
  // It's an attribute to remove some tracking functionality from a screen recording tool that they are using. [MISSION-2022]
  isHJSuppress = undefined,
  isSticky = false,
}) => {
  const rootClass = clsx(
    classes.root,
    {
      [classes.withLoader]: loading,
    },
    rootClassName
  )
  const bodyClass = clsx(
    classes.body,
    {
      [classes.noTopPadding]: title && !withDivider,
    },
    bodyClassName
  )

  let header: React.ReactElement | null = null

  if (customHeader) header = customHeader
  else if (title) {
    const headerRootClassName = clsx({ [classes.stickyHeader]: isSticky })

    header = (
      <PaperHeader
        testId={headerTestId}
        bigHeader={bigHeader}
        withError={withError}
        interactive={interactive}
        backUrl={backUrl}
        withDivider={withDivider}
        headerTextClassName={headerClassName}
        CustomLeftSlot={CustomLeftSlot}
        CustomRightSlot={CustomRightSlot}
        headerRootClassName={headerRootClassName}
      >
        {title}
      </PaperHeader>
    )
  }

  return (
    <MUIPaper
      elevation={0}
      className={rootClass}
      id={anchor}
      data-testid={testId}
    >
      {loading ? (
        <CircularProgress
          color="inherit"
          size={loaderSize}
          data-testid="loader"
        />
      ) : (
        <>
          {header}

          <Box className={bodyClass} data-hj-suppress={isHJSuppress}>
            {children}
          </Box>
        </>
      )}
    </MUIPaper>
  )
}

export default Paper

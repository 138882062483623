import React, { MouseEventHandler } from "react"
import clsx from "clsx"

import { IAdvisorProductType, IAdvisorStatus } from "../../../types"
import { isCall } from "../../Experts/Products/utils"
import AdviqoButton from "../AdviqoButton"
import { IAdviqoButtonProps } from "../AdviqoButton/AdviqoButton"
import CallIcon from "../QuesticoIcon/CallIcon"
import CallBackIcon from "../QuesticoIcon/CallBackIcon"
import ChatIcon from "../QuesticoIcon/ChatIcon"
import ChatNotOfferedIcon from "../QuesticoIcon/ChatNotOfferedIcon"
import CallNotOfferedIcon from "../QuesticoIcon/CallNotOfferedIcon"
import classes from "./ProductButton.module.scss"

const ProductButtonBase: React.FC<IAdviqoButtonProps> = (props) => (
  <AdviqoButton {...props} fullWidth />
)

interface IProductButtonProps
  extends Omit<
    IAdviqoButtonProps,
    "icon" | "label" | "primary" | "secondary" | "fullWidth" | "variant"
  > {
  productType: IAdvisorProductType
  available: boolean
  isFreeProduct: boolean
  advisorStatus?: IAdvisorStatus
  /**
   * false by default
   */
  className?: string
  onClick: MouseEventHandler
  labelText?: string
  withLabel?: boolean
  isReservationFlow?: boolean
  /**
   * is there ongoing conversation?
   * @note we are using this flag to alter all buttons state to disable them
   * so user won't be able to interact with other products while one is already in use
   *
   * false by default
   */
  productIsActive?: boolean
}

type TProductButtonState =
  | "active"
  | "callback"
  | "busy"
  | "offline"
  | "not_available"

function getProductButtonState(
  advisorStatus: IAdvisorStatus | undefined,
  isProductAvailable: boolean,
  isCall: boolean,
  isReservationFlow: boolean
): TProductButtonState {
  if (isReservationFlow && isCall) {
    return "callback"
  }

  switch (advisorStatus) {
    case "online": {
      return isProductAvailable ? "active" : "not_available"
    }

    case "busy": {
      if (!isProductAvailable) {
        return "not_available"
      }

      return isCall ? "callback" : "busy"
    }

    case "offline": {
      if (!isProductAvailable) {
        return "not_available"
      }

      return isCall ? "callback" : "offline"
    }

    default:
      return "active"
  }
}

function getProductButtonProps(
  productButtonState: TProductButtonState,
  isCall: boolean,
  productIsActive: boolean,
  isFree: boolean | undefined,
  isDisabled: boolean | undefined
) {
  let icon = isCall ? <CallIcon /> : <ChatIcon />
  let disabled = false
  let label = `Jetzt ${isCall ? "anrufen" : "chatten"}`

  switch (productButtonState) {
    case "active":
      if (isFree) {
        label = `${isCall ? "Gratis anrufen" : "Gratis chatten"}`
      } else {
        label = `${isCall ? "Anrufen" : "Chatten"}`
      }
      break
    case "busy":
      disabled = true
      label = "Besetzt"
      break
    case "offline":
      disabled = true
      if (isCall) {
        label = `${isFree ? "Gratis anrufen" : "Anrufen"}`
      } else {
        label = "Nicht verfügbar"
      }
      break
    case "callback":
      icon = <CallBackIcon />
      label = "Rückruf"
      break
    case "not_available":
      icon = isCall ? <CallNotOfferedIcon /> : <ChatNotOfferedIcon />
      disabled = true
      label = "Nicht angeboten"
      break
    default:
  }

  if (productIsActive || isDisabled) {
    disabled = true
  }

  return {
    icon,
    disabled,
    label,
  }
}

const ProductButton: React.FC<IProductButtonProps> = ({
  isFreeProduct,
  available,
  productType,
  className,
  advisorStatus,
  size = "medium",
  productIsActive = false,
  disabled: isDisabled,
  labelText,
  withLabel = true,
  isReservationFlow = false,
  ...rest
}) => {
  const itIsCall = isCall(productType)

  const productButtonState = getProductButtonState(
    advisorStatus,
    available,
    itIsCall,
    isReservationFlow
  )

  const { icon, disabled, label } = getProductButtonProps(
    productButtonState,
    itIsCall,
    productIsActive,
    isFreeProduct,
    isDisabled
  )

  const buttonClassName = clsx(
    classes.button,
    {
      [classes.callbackButton]: productButtonState === "callback",
      [classes.notAvailableButton]:
        productButtonState === "not_available" ||
        productButtonState === "offline",
      [classes.withoutLabelButton]: !withLabel,
    },
    className
  )

  return (
    <ProductButtonBase
      {...rest}
      size={size}
      icon={icon}
      disabled={disabled}
      disableTouchRipple={disabled}
      variant={productButtonState === "callback" ? "outlined" : undefined}
      buttonClassName={buttonClassName}
      {...(withLabel && {
        label: (
          <span className={classes.productTypeText}>{labelText || label}</span>
        ),
      })}
      iconClassName={clsx(classes.icon, {
        [classes.withoutLabelButtonIcon]: !withLabel,
      })}
    />
  )
}

export default ProductButton

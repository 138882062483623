import React, { useRef, useEffect, ReactNode } from "react"
import clsx from "clsx"

import { Box, useTheme, useMediaQuery } from "../MaterialUI"
import { useDomRefs } from "../../../hooks/domRefs"
import MailIcon from "../QuesticoIcon/MailIcon"
import classes from "./Banner.module.scss"

const iconSize = {
  xs: 15,
  md: 24,
}

interface IBannerProps {
  iconNode?: React.ReactNode
  rootClassName?: string
  iconClassName?: string
  isVerificationEmailSent: boolean
  children: ReactNode
}

const Banner: React.FC<IBannerProps> = ({
  iconNode,
  children,
  rootClassName,
  iconClassName,
  isVerificationEmailSent,
}) => {
  const bannerRef = useRef<HTMLDivElement>(null)
  const { addRef, delRef, refsMap } = useDomRefs()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("md"))

  useEffect(() => {
    if (bannerRef.current && !refsMap.banner) {
      addRef("banner", bannerRef)
    }

    return () => {
      if (refsMap.banner) delRef("banner")
    }
  }, [addRef, delRef, isVerificationEmailSent, refsMap.banner])

  return (
    <div className={clsx(classes.root, rootClassName)} ref={bannerRef}>
      <Box className={clsx(classes.icon, iconClassName)}>
        {iconNode || <MailIcon width={isMobile ? iconSize.xs : iconSize.md} />}
      </Box>
      <Box className={classes.info}>{children}</Box>
    </div>
  )
}

export default Banner

import React from "react"
import clsx from "clsx"
import { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar"

import { Container, MuiAppBar } from "../MaterialUI"
import { CONFIG } from "../../NewNavigation/consts"
import classes from "./AppBar.module.scss"

interface IAppBarProps extends Pick<MuiAppBarProps, "position"> {
  topOffset?: number
  navBarRef?: React.RefObject<HTMLDivElement>
  contaitnerClassName?: string
  appBarClassName?: string
  component?: "header" | "div"
  children: React.ReactNode
}

const AppBar: React.FC<IAppBarProps> = ({
  appBarClassName,
  contaitnerClassName,
  position = "relative",
  navBarRef = null,
  topOffset = 0,
  children,
  component = "header",
}) => {
  const containerClass = clsx(classes.container, contaitnerClassName)
  const appBarClass = clsx(classes.appBar, appBarClassName)

  return (
    <>
      <MuiAppBar
        ref={navBarRef}
        className={appBarClass}
        component={component}
        position={position}
        elevation={0}
        sx={{ top: topOffset }}
      >
        <Container
          sx={{ minHeight: CONFIG.APP_BAR.height }}
          className={containerClass}
        >
          {children}
        </Container>
      </MuiAppBar>
    </>
  )
}

export default AppBar

import React from "react"
import clsx from "clsx"
import Paper from "../Paper"
import classes from "./MainLoader.module.scss"

interface IMainLoaderProps {
  containerClassName?: string
  loaderSize?: number
  /**
   * will position loader absolutely
   * with 100% width and height
   */
  fullwidth?: boolean
}

const MainLoader: React.FC<IMainLoaderProps> = ({
  containerClassName,
  loaderSize,
  fullwidth = true,
}) => {
  const rootClassName = clsx(
    classes.container,
    {
      [classes.fullwidth]: fullwidth,
    },
    containerClassName
  )

  return (
    <Paper
      rootClassName={rootClassName}
      withError={false}
      loading
      loaderSize={loaderSize}
    />
  )
}

export default MainLoader

import React, { createContext, useRef, useContext, ReactNode } from "react"

import {
  getRuntimeConfig as getPublicRuntimeConfig,
  IPublicRuntimeConfig,
} from "../../utils/runtimeConfig"
interface IRuntimeConfigContextProps {
  getRuntimeConfig: typeof getPublicRuntimeConfig
  getRuntimeConfigByKey: (key: keyof IPublicRuntimeConfig) => any
}

export const RuntimeConfigContext = createContext<IRuntimeConfigContextProps>({
  getRuntimeConfig: () => getPublicRuntimeConfig(),
  getRuntimeConfigByKey: () => undefined,
})

export const RuntimeConfigProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const publicRuntimeConfig = useRef(getPublicRuntimeConfig())

  const props: IRuntimeConfigContextProps = {
    getRuntimeConfig: () => {
      return publicRuntimeConfig.current
    },
    getRuntimeConfigByKey: (key) => {
      return publicRuntimeConfig.current[key]
    },
  }

  return (
    <RuntimeConfigContext.Provider value={props}>
      {children}
    </RuntimeConfigContext.Provider>
  )
}

export const useRuntimeConfig = () => useContext(RuntimeConfigContext)

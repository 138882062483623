"use client"

import { useRuntimeConfig } from "../../../../hooks/runtimeConfig"
import { BrandsEnum } from "../../../../types"

export function useCheckBrand(): {
  isQuesticoBrand: boolean
  isViversumBrand: boolean
} {
  const { getRuntimeConfigByKey } = useRuntimeConfig()
  const brandNumber = getRuntimeConfigByKey("MANDATOR_NUMBER")
  return {
    isQuesticoBrand: brandNumber === BrandsEnum.QUESTICO,
    isViversumBrand: brandNumber === BrandsEnum.VIVERSUM_GERMANY,
  }
}

import React from "react"
import AdviqoLink from "../AdviqoLink"
import {
  QuesticoLogoMinimal,
  QuesticoLogoFull,
  ViversumLogoMinimal,
  ViversumLogoFull,
} from "./Image"
import { Box } from "../MaterialUI"
import { useRuntimeConfig } from "../../../hooks/runtimeConfig"
import { BrandsEnum } from "../../../types"
import { IImageSize, ILogoColorVariations } from "./types"
import { getBrandName } from "../../../utils/utils"

interface ILogoProps extends IImageSize {
  /** `false` by default. Adds brand name to the logo */
  /** `true` by default */
  withLink?: boolean
  /** Leads to the home page by default */
  href?: string
  wrapperClassName?: string
  onClick?: React.MouseEventHandler
  onDown?: (e: React.MouseEvent | React.TouchEvent) => void
  onUp?: (e: React.MouseEvent | React.TouchEvent) => void
  colorVariation?: ILogoColorVariations
  sizeVariation?: "minimal" | "full"
}

const getLogo = (
  size: IImageSize["size"],
  sizeVariation: ILogoProps["sizeVariation"],
  colorVariation: ILogoColorVariations,
  mandatorNumber: BrandsEnum
) => {
  if (mandatorNumber === BrandsEnum.QUESTICO && sizeVariation === "minimal") {
    return <QuesticoLogoMinimal size={size} variation={colorVariation} />
  }
  if (mandatorNumber === BrandsEnum.QUESTICO && sizeVariation === "full") {
    return <QuesticoLogoFull size={size} variation={colorVariation} />
  }
  if (
    mandatorNumber === BrandsEnum.VIVERSUM_GERMANY &&
    sizeVariation === "minimal"
  ) {
    return <ViversumLogoMinimal size={size} variation={colorVariation} />
  }
  return <ViversumLogoFull size={size} variation={colorVariation} />
}

const Logo: React.FC<ILogoProps> = ({
  size,
  withLink = true,
  href = "/",
  wrapperClassName,
  onClick,
  onDown,
  onUp,
  colorVariation = "default",
  sizeVariation = "minimal",
}) => {
  const { getRuntimeConfigByKey } = useRuntimeConfig()
  const mandatorNumber = getRuntimeConfigByKey("MANDATOR_NUMBER")
  const image = getLogo(size, sizeVariation, colorVariation, mandatorNumber)

  if (withLink) {
    return (
      <AdviqoLink
        to={href}
        style={{ fontSize: 0, display: "block" }}
        className={wrapperClassName}
        onClick={onClick}
        onMouseDown={onDown}
        onMouseUp={onUp}
        onTouchStart={onDown}
        onTouchEnd={onUp}
        aria-label={getBrandName()}
      >
        {image}
      </AdviqoLink>
    )
  }

  return (
    <Box
      style={{ fontSize: 0 }}
      className={wrapperClassName}
      onMouseDown={onDown}
      onMouseUp={onUp}
      onTouchStart={onDown}
      onTouchEnd={onUp}
    >
      {image}
    </Box>
  )
}

export default Logo

import React, { useCallback } from "react"

import BrowserFeaturesContext from "./BrowserFeaturesContext"
import { TBrowserFeature, TFeatures } from "./types"

interface IBrowserFeaturesProviderProps {
  features: TFeatures | undefined
  children: React.ReactNode
}

const BrowserFeaturesProvider: React.FC<IBrowserFeaturesProviderProps> = ({
  children,
  features,
}) => {
  const isFeatureSupported = useCallback(
    (feat: TBrowserFeature) => {
      return Boolean(features?.[feat])
    },
    [features]
  )

  const getDomainPath = useCallback(() => {
    if (typeof window === "undefined") {
      return features?.domainPath as string
    }

    return window.location.origin
  }, [features])

  const value = {
    isFeatureSupported,
    getDomainPath,
  }

  return (
    <BrowserFeaturesContext.Provider value={value}>
      {children}
    </BrowserFeaturesContext.Provider>
  )
}

export default BrowserFeaturesProvider

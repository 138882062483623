import React from "react"
import clsx from "clsx"

import { CircularProgress } from "../MaterialUI"
import AdviqoButton from "../AdviqoButton"
import { IAdviqoButtonProps } from "../AdviqoButton/AdviqoButton"
import classes from "./LoadMoreButton.module.scss"

interface LoadMoreButtonProps extends IAdviqoButtonProps {
  isLoading: boolean
}

const LoadMoreButton: React.FC<LoadMoreButtonProps> = ({
  label,
  isLoading,
  labelClassName,
  buttonClassName,
  ...buttonProps
}): JSX.Element => {
  const lblClassName = clsx({ [classes.loading]: isLoading }, labelClassName)

  return (
    <AdviqoButton
      {...buttonProps}
      testId="experts-loadmore"
      color="primary"
      buttonClassName={clsx(classes.button, buttonClassName)}
      labelClassName={lblClassName}
      disabled={isLoading}
      label={isLoading ? <CircularProgress thickness={2} size={20} /> : label}
    />
  )
}

export default LoadMoreButton

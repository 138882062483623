import React from "react"
import Image, { ImageProps } from "next/image"

type NextImageProps = ImageProps & {
  width?: number
  height?: number
  targetHeight?: number
}
const NextImage: React.FC<NextImageProps> = (props) => {
  const { src, targetHeight, height, width, ...rest } = props

  const imgSizes = {
    width,
    height: targetHeight || height,
  }

  if (targetHeight && width && height) {
    const coef = height / targetHeight
    imgSizes.width = parseFloat((width / coef).toFixed(2))
  }

  // @ts-ignore
  return <Image {...rest} {...imgSizes} src={src} />
}

export default NextImage

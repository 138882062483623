import React from "react"
import clsx from "clsx"
import MUIExpansionPanel from "@mui/material/Accordion"
import AccordionDetails from "@mui/material/AccordionDetails"
import AccordionSummary from "@mui/material/AccordionSummary"
import { Typography, ExpandMoreIcon, Box } from "../MaterialUI"
import classes from "./ExpansionPanel.module.scss"

interface IExpansionPanelItem {
  title: string | React.ReactNode
  subTitle?: string | React.ReactNode
  details: string | React.ReactNode
}

export type TExpansionPanelItems = Array<IExpansionPanelItem>
interface IExpansionPanelProps {
  items: Array<IExpansionPanelItem>
  panelClassName?: string
  expandedClassName?: string
  headingClassName?: string
  subHeadingClassName?: string
  contentClassName?: string
  summaryClassName?: string
  detailsClassName?: string
  detailsWrapperClassName?: string
  defaultExpanded?: boolean
  expanded?: boolean
  onChange?: (_: React.ChangeEvent<unknown>, isExpanded: boolean) => void
}

const ExpansionPanel: React.FC<IExpansionPanelProps> = ({
  items,
  panelClassName,
  expandedClassName,
  headingClassName,
  subHeadingClassName,
  contentClassName,
  summaryClassName,
  detailsClassName,
  detailsWrapperClassName,
  defaultExpanded = false,
  onChange,
  expanded,
}) => {
  return (
    <>
      {items.map((item, index) => (
        <MUIExpansionPanel
          // eslint-disable-next-line react/no-array-index-key
          key={index}
          defaultExpanded={defaultExpanded}
          classes={{
            root: classes.expansionPanel,
            expanded: expandedClassName,
          }}
          className={panelClassName}
          onChange={onChange}
          expanded={expanded}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            classes={{
              content: contentClassName,
            }}
            className={summaryClassName}
            data-testid="expansion-panel"
          >
            <Box>
              {item.subTitle && (
                <Typography
                  className={clsx(classes.subHeading, subHeadingClassName)}
                >
                  {item.subTitle}
                </Typography>
              )}
              <Typography className={clsx(classes.heading, headingClassName)}>
                {item.title}
              </Typography>
            </Box>
          </AccordionSummary>
          <AccordionDetails className={clsx(classes.details, detailsClassName)}>
            <Box className={detailsWrapperClassName}>{item.details}</Box>
          </AccordionDetails>
        </MUIExpansionPanel>
      ))}
    </>
  )
}

export default ExpansionPanel

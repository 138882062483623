import dynamic from "next/dynamic"
import React from "react"
import { IAnimatedIconProps } from "./types"

const LazyLoadedIcon = dynamic(() => import("./AnimatedIcon"), { ssr: false })

const LazyAnimatedIcon: React.FC<IAnimatedIconProps> = (props) => (
  <LazyLoadedIcon {...props} />
)

export default LazyAnimatedIcon

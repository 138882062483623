import React from "react"
import { CSSTransition } from "react-transition-group"
import classes from "./FadeTransition.module.scss"

interface IFadeTransitionProps {
  show: boolean
  timeout?: number
  children: React.ReactNode
}

const FadeTransition: React.FC<IFadeTransitionProps> = ({
  children,
  show,
  timeout = 500,
}) => {
  return (
    // @ts-ignore
    <CSSTransition
      in={show}
      timeout={timeout}
      classNames={{
        enter: classes.enter,
        enterActive: classes.enterActive,
        exit: classes.exit,
        exitActive: classes.exitActive,
      }}
      unmountOnExit
    >
      {children}
    </CSSTransition>
  )
}

export default FadeTransition

import React from "react"
import IconBase from "./IconBase"
import { IIconProps } from "./types"

const ArrowIcon: React.FC<IIconProps> = ({
  color = "currentColor",
  className,
}) => (
  <IconBase className={className}>
    <svg width="100%" height="100%" viewBox="0 0 14 11">
      <path
        fill={color}
        d="M7 -1.5L1.5 3.916 2.5 4.902 6.293 1.167 6.293 12.5 7.707 12.5 7.707 1.167 11.5 4.902 12.5 3.916z"
        transform="matrix(0 1 1 0 1.5 -1.5)"
      />
    </svg>
  </IconBase>
)

export default ArrowIcon

"use client"

import React, { useState, useEffect, ReactNode } from "react"
import { Breakpoint } from "@mui/material/styles"

import { questicoTheme } from "../../themes/questico"
import ViewportContext from "./ViewportContext"

const getViewport = (): Breakpoint => {
  const width = typeof window !== "undefined" ? window.innerWidth : 0

  if (width > questicoTheme.breakpoints.values.xl) {
    return "xl"
  }

  if (width > questicoTheme.breakpoints.values.lg) {
    return "lg"
  }

  if (width > questicoTheme.breakpoints.values.md) {
    return "md"
  }

  if (width > questicoTheme.breakpoints.values.sm) {
    return "sm"
  }

  return "xs"
}

const ViewportProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [viewport, setViewport] = useState<Breakpoint>("xs")

  const resizeHandler = () => {
    setViewport(getViewport())
  }

  useEffect(() => {
    setViewport(getViewport())

    window.addEventListener("resize", resizeHandler)

    return () => {
      window.removeEventListener("resize", resizeHandler)
    }
  }, [])

  return (
    <ViewportContext.Provider value={viewport}>
      {children}
    </ViewportContext.Provider>
  )
}

export default ViewportProvider

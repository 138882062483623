import React from "react"
import clsx from "clsx"

import {
  FormHelperText as MUIFormHelperText,
  Box,
  useTheme,
} from "../MaterialUI"
import ErrorCircleSmallIcon from "../QuesticoIcon/ErrorCircleSmallIcon"
import SuccessCircleIcon from "../QuesticoIcon/SuccessCircleIcon"
import { TMessageType } from "../../../types"
import InfoIcon from "../QuesticoIcon/InfoIcon"
import classes from "./FieldStatusHelpText.module.scss"

interface IFieldStatusBase {
  withIcon?: boolean
  className?: string
  testId?: string
  children: React.ReactNode
}

interface IFieldStatusHelpTextProps extends IFieldStatusBase {
  variant: TMessageType
}

const FieldError: React.FC<IFieldStatusBase> = ({
  children,
  className,
  ...rest
}) => {
  const theme = useTheme()

  return (
    <MUIFormHelperText
      {...rest}
      error
      className={clsx(classes.root, className)}
    >
      <Box component="span" className={classes.iconWrapper}>
        <ErrorCircleSmallIcon color={theme.palette.error.main} />
      </Box>
      {children}
    </MUIFormHelperText>
  )
}

const FieldSuccess: React.FC<IFieldStatusBase> = ({
  children,
  className,
  ...rest
}) => {
  return (
    <MUIFormHelperText
      {...rest}
      className={clsx(classes.root, classes.successText, className)}
    >
      <Box component="span" className={classes.iconWrapper}>
        <SuccessCircleIcon />
      </Box>
      {children}
    </MUIFormHelperText>
  )
}

const FieldInfo: React.FC<IFieldStatusBase> = ({
  children,
  className,
  withIcon = true,
  ...rest
}) => {
  return (
    <MUIFormHelperText {...rest} className={clsx(classes.root, className)}>
      {withIcon && (
        <Box component="span" className={classes.iconWrapper}>
          <InfoIcon />
        </Box>
      )}

      {children}
    </MUIFormHelperText>
  )
}

const FieldStatusHelpText: React.FC<IFieldStatusHelpTextProps> = ({
  children,
  variant,
  ...rest
}) => {
  switch (variant) {
    case "error":
      return (
        <FieldError data-testid="field-help-error" {...rest}>
          {children}
        </FieldError>
      )
    case "success":
      return (
        <FieldSuccess data-testid="field-help-success" {...rest}>
          {children}
        </FieldSuccess>
      )
    case "info":
      return (
        <FieldInfo data-testid="field-help-info" {...rest}>
          {children}
        </FieldInfo>
      )
    default:
      return null
  }
}

export default FieldStatusHelpText

import clsx from "clsx"
import React from "react"
import classes from "./IconBase.module.scss"
interface IIconBaseProps {
  className?: string
  children: React.ReactNode
}

const IconBase: React.FC<IIconBaseProps> = ({ children, className }) => {
  return <span className={clsx(classes.root, className)}>{children}</span>
}

export default IconBase

import React from "react"
import clsx from "clsx"
import { Backdrop, Fade, MUIModal } from "../MaterialUI"
import { IModalBaseProps } from "./types"
import CloseIcon from "../QuesticoIcon/CloseIcon"
import classes from "./ModalBase.module.scss"

const Modal: React.FC<IModalBaseProps> = ({
  id,
  opened,
  closeModal,
  children,
  canCloseOnBackdrop = true,
  rootClassName,
  wrapperClassName,
  closeClassName,
  modalProps,
}) => {
  return (
    <MUIModal
      id={id}
      aria-labelledby="login-registration"
      aria-describedby="login-registration"
      open={opened}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
      onClose={canCloseOnBackdrop ? closeModal : undefined}
      className={clsx(classes.modal, rootClassName)}
      {...modalProps}
    >
      <Fade in={opened}>
        <div className={clsx(classes.wrapper, wrapperClassName)}>
          <div className={classes.container}>{children}</div>
          <button
            className={clsx(classes.closeButton, closeClassName)}
            onClick={closeModal}
            type="button"
          >
            <CloseIcon />
          </button>
        </div>
      </Fade>
    </MUIModal>
  )
}

export default Modal

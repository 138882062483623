import { ApolloError } from "@apollo/client"

import { IExpert, IAdvisorStatus } from "../../types"
import { IExperts } from "../Experts/ExpertsService/hooks/types"
import { ITabsList } from "../TabBar/types"
import { IExpertListingProps } from "../Experts/types"

export interface IContainerFooterLinkProps {
  title: string
  to: string
  buttonClassName?: string
  footerClassName?: string
}

export interface IExpertsHash {
  [k: string]: IExpert
}

export interface IFetchMyExpertsList {
  loadMore: (newPageNumber: number) => void
  expertsListing: IExperts
  currentPageNumber: number
  loading: boolean
  error: ApolloError | Error | undefined
}

export type FavoriteExpertsStatusesCount = {
  [key in Required<IAdvisorStatus>]?: number
}

export interface IRecentExpertsItem {
  id: number
}

export interface IRecentExpertsData {
  [userId: string]: IRecentExpertsItem[]
}

export enum ExpertsCategory {
  Popular = 20003142,
  New = 20003061,
}

export type TListing = "recommendations" | "my-advisors"

export type TMyExpertListing =
  | "recent"
  | "favorite"
  | "forYou"
  | "popular"
  | "new"

export type TMyAdvisorListing = Omit<
  TMyExpertListing,
  "forYou" | "popular" | "new"
>

export type TRecommendationsListing = Omit<
  TMyExpertListing,
  "recent" | "favorite"
>

export interface IExpertsTabList extends ITabsList {
  value: TMyExpertListing
}

export interface IMyExpertListProps extends IExpertListingProps {
  listing: TMyExpertListing
  headlineText?: string
  withProducts?: boolean
  withFavouriteButton?: boolean
  withUndo?: boolean
  withTags?: boolean
  withAddFavorite?: boolean
}

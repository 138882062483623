import React from "react"
import clsx from "clsx"

import { Box, Typography, useTheme, Hidden } from "../MaterialUI"
import ChevronIcon from "../QuesticoIcon/ChevronIcon"
import AdviqoLink from "../AdviqoLink"
import commonStyles from "../../../styles/common.module.scss"
import classes from "./PaperHeader.module.scss"

interface IPaperHeaderCommonProps {
  withError?: boolean
  testId?: string
  /** true by default. Adds border below title, if title exists */
  withDivider: boolean
  /** false by default. Uses bigger font sizes and spasings. Needed to support call and chat flows */
  bigHeader: boolean
}

export interface IPaperHeaderProps extends IPaperHeaderCommonProps {
  /** undefined by default. Defines whether or not will have back arrow on mobile and desktop */
  interactive?: "mobile" | "desktop" | "both"
  /** undefined by default. Url to be redirected to if `interactive` prop is true, required in this case. */
  backUrl?: string
  headerTextClassName?: string
  headerRootClassName?: string
  // eslint-disable-next-line @typescript-eslint/naming-convention
  CustomLeftSlot?: React.ReactNode
  // eslint-disable-next-line @typescript-eslint/naming-convention
  CustomRightSlot?: React.ReactNode
  children: React.ReactNode
}

const makeSpacingsClasses = (options: Pick<IHeaderTextProps, "bigHeader">) =>
  clsx(commonStyles.spacingsRegular, {
    [commonStyles.spacingsBig]: options.bigHeader,
  })

interface IHeaderTextProps extends IPaperHeaderCommonProps {
  className?: string
  withSpacings: boolean
  children: React.ReactNode
}

export const HeaderText: React.FC<IHeaderTextProps> = ({
  children,
  withError,
  className,
  bigHeader,
  testId = undefined,
  withDivider,
  withSpacings,
}) => {
  const textClassName = clsx(
    classes.text,
    {
      [classes.textBig]: bigHeader,
      [classes.textWithDivider]: withDivider,
      [makeSpacingsClasses({ bigHeader })]: withSpacings,
    },
    className
  )

  return (
    <Typography
      color={withError ? "error" : "textPrimary"}
      component="h5"
      variant="subtitle1"
      className={textClassName}
      data-testid={testId}
    >
      {children}
    </Typography>
  )
}

export const PaperHeaderRoot: React.FC<{
  rootClassName?: string
  children: React.ReactNode
}> = ({ children, rootClassName }) => {
  const className = clsx(classes.root, rootClassName)

  return <Box className={className}>{children}</Box>
}

const PaperHeader: React.FC<IPaperHeaderProps> = ({
  children,
  withDivider,
  bigHeader,
  withError = false,
  interactive = undefined,
  backUrl = undefined,
  testId = undefined,
  headerTextClassName,
  headerRootClassName,
  CustomLeftSlot = null,
  CustomRightSlot = null,
}) => {
  const theme = useTheme()
  const linkClassName = clsx(classes.link, makeSpacingsClasses({ bigHeader }), {
    [classes.textWithDivider]: withDivider,
    [classes.linkDesktop]: interactive,
    [classes.extraLeftOffset]: !!CustomLeftSlot,
    [classes.extraRightOffset]: !!CustomRightSlot,
  })

  const textWithoutLink = (
    <HeaderText
      testId={testId}
      withError={withError}
      withDivider={withDivider}
      withSpacings
      bigHeader={bigHeader}
      className={clsx(
        {
          [classes.extraLeftOffset]: !!CustomLeftSlot,
          [classes.extraRightOffset]: !!CustomRightSlot,
        },
        headerTextClassName
      )}
    >
      {children}
    </HeaderText>
  )

  let child: React.ReactNode = null

  if (backUrl && interactive) {
    const link = (
      <AdviqoLink to={backUrl} className={linkClassName}>
        <Box className={classes.arrow}>
          <ChevronIcon color={theme.palette.grey[300]} height={19} width={11} />
        </Box>
        <HeaderText
          className={headerTextClassName}
          bigHeader={bigHeader}
          withError={withError}
          testId={testId}
          withDivider={false}
          withSpacings={false}
        >
          {children}
        </HeaderText>
      </AdviqoLink>
    )

    if (interactive === "mobile") {
      child = (
        <>
          <Hidden mdUp>{link}</Hidden>
          <Hidden mdDown>{textWithoutLink}</Hidden>
        </>
      )
    } else if (interactive === "desktop") {
      child = (
        <>
          <Hidden mdDown>{link}</Hidden>
          <Hidden mdUp>{textWithoutLink}</Hidden>
        </>
      )
    } else if (interactive === "both") {
      child = link
    }
  } else {
    child = textWithoutLink
  }

  return (
    <PaperHeaderRoot rootClassName={headerRootClassName}>
      {CustomLeftSlot}
      {child}
      {CustomRightSlot}
    </PaperHeaderRoot>
  )
}

export default PaperHeader

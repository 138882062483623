"use client"

import React, { forwardRef, useState } from "react"

import { IconButton, CloseIcon, MuiSnackbar, MuiSnackbarContent } from ".."
import classes from "./Snackbar.module.scss"
import { SnackbarCloseReason, SnackbarOrigin } from "@mui/material"
import clsx from "clsx"

interface ISnackbarProps {
  children: string | React.ReactNode
  anchorOrigin?: SnackbarOrigin
  closingComponent: "icon" | "text" | undefined
  isOpen: boolean
  autoHideDuration?: number
  testId?: string
  onClick?: () => void
  onClose?: () => void
  className?: string
  contentClassName?: string
  preventCloseOnClickAway?: boolean
}

const Snackbar: React.ForwardRefRenderFunction<
  HTMLInputElement,
  ISnackbarProps
> = (
  {
    children,
    anchorOrigin = { horizontal: "right", vertical: "bottom" },
    closingComponent,
    isOpen,
    autoHideDuration,
    testId,
    onClick,
    onClose,
    className,
    contentClassName,
    preventCloseOnClickAway = false,
  },
  ref
) => {
  const [open, setOpen] = useState(isOpen)

  const rootClasses = clsx(
    classes.snackbar,
    className,
    classes[anchorOrigin.horizontal],
    classes[anchorOrigin.vertical]
  )

  const contentClasses = clsx(
    classes.content,
    {
      [classes.contentClickable]: Boolean(onClick),
    },
    contentClassName
  )

  const handleClose = (
    _: Event | React.SyntheticEvent<unknown, Event>,
    reason: SnackbarCloseReason
  ) => {
    if (preventCloseOnClickAway && reason === "clickaway") {
      return
    }

    if (onClose) {
      onClose()
    }

    setOpen(false)
  }

  return (
    <MuiSnackbar
      open={open}
      className={rootClasses}
      anchorOrigin={anchorOrigin}
      autoHideDuration={autoHideDuration}
      onClose={handleClose}
      ref={ref}
    >
      <MuiSnackbarContent
        classes={{
          root: contentClasses,
          message: classes.message,
        }}
        message={children}
        onClick={onClick}
        {...(closingComponent && {
          action: (
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={() => {
                setOpen(false)

                if (onClose) {
                  onClose()
                }
              }}
              data-testid={testId}
              className={classes.closeButton}
            >
              {closingComponent === "icon" && <CloseIcon fontSize="small" />}
              {closingComponent === "text" && "OK"}
            </IconButton>
          ),
        })}
      />
    </MuiSnackbar>
  )
}

export default forwardRef<HTMLInputElement, ISnackbarProps>(Snackbar)

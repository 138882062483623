import clsx from "clsx"
import React from "react"

import AdviqoButton from "../AdviqoButton"
import classes from "./LinkButton.module.scss"

type ILinkButtonProps = Omit<
  React.ComponentProps<typeof AdviqoButton>,
  "size" | "variant"
>

const LinkButton: React.FC<ILinkButtonProps> = ({
  buttonClassName,
  labelClassName,
  ...rest
}) => {
  return (
    <AdviqoButton
      {...rest}
      size="small"
      buttonClassName={clsx(classes.linkButton, buttonClassName)}
      labelClassName={clsx(classes.linkButtonLabel, labelClassName)}
      variant="text"
    />
  )
}

export default LinkButton

import React from "react"
import { IIconProps } from "./types"

const ErrorCircleIcon: React.FC<IIconProps> = ({
  color = "currentColor",
  height = "100%",
  width = "100%",
}) => (
  <svg width={width} height={height} viewBox="0 0 60 60">
    <g fill={color}>
      <path
        d="M28.363 37.757L32.418 37.757 32.418 43.4 28.363 43.4z"
        transform="rotate(-.573 30.39 40.578)"
      />
      <path
        d="M28.114 13.924L32.168 13.924 32.168 33.61 28.114 33.61z"
        transform="rotate(-.38 30.14 23.767)"
      />
      <path d="M29.74 59.481C13.316 59.481 0 46.166 0 29.741 0 13.315 13.315 0 29.74 0c16.426 0 29.741 13.315 29.741 29.74-.018 16.418-13.323 29.723-29.74 29.741zm0-55.427c-14.186 0-25.686 11.5-25.686 25.687 0 14.186 11.5 25.686 25.687 25.686 14.186 0 25.686-11.5 25.686-25.686-.018-14.18-11.508-25.67-25.686-25.687z" />
    </g>
  </svg>
)

export default ErrorCircleIcon

import React from "react"
import clsx from "clsx"

import CloseIcon from "../QuesticoIcon/CloseIcon"
import LinkButton from "./LinkButton"
import classes from "./EditDataButton.module.scss"

interface IEditaDataButtonProps
  extends Omit<React.ComponentProps<typeof LinkButton>, "label"> {
  /**
   * adds CloseIcon to the children
   */
  withCloseIcon?: boolean
}

const EditDataButton: React.FC<IEditaDataButtonProps> = ({
  buttonClassName,
  children,
  disabled = false,
  withCloseIcon = false,
  ...rest
}) => {
  const btnClassName = clsx(
    { [classes.editButtonClose]: withCloseIcon },
    buttonClassName
  )

  return (
    <LinkButton
      {...rest}
      icon={withCloseIcon ? <CloseIcon /> : undefined}
      label={children}
      buttonClassName={btnClassName}
      labelClassName={classes.label}
      iconClassName={classes.icon}
      disabled={disabled}
      color="primary"
      disableTouchRipple
    />
  )
}

export default EditDataButton

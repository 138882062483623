import { CheckIcon } from "../MaterialUI"
import AdviqoButton from "../AdviqoButton"
import HeartIcon from "../QuesticoIcon/HeartIcon"

interface FavouriteButtonBaseProps {
  isFavourite: boolean
  onClick: React.MouseEventHandler
  buttonClassName?: string
}

const FavouriteButton: React.FC<Omit<
  FavouriteButtonBaseProps,
  "round" | "fullWidth" | "label"
>> = ({ isFavourite, ...props }) => (
  <AdviqoButton
    round
    color="secondary"
    fullWidth={false}
    icon={isFavourite ? <CheckIcon /> : <HeartIcon width={13} />}
    {...props}
  />
)

export default FavouriteButton

// import { createBreakpoints } from "../ui-lib/_common/MaterialUI"

// The default font size of the Material Specification.
export const fontSize = 14
// Tell Material-UI what's the font-size on the html element.
// 16px is the default font-size used by browsers.
export const htmlFontSize = 16
const coef = fontSize / 14
export const pxToRem = (size: number): string =>
  `${(size / htmlFontSize) * coef}rem`
// export const breakpoints = createBreakpoints({})

export const calcColumnWidth = (columns = 12, gap = 8): string => {
  const colsInRow = Math.round(12 / columns)

  return `calc(${columns} / 12 * 100% - ${
    (gap * (colsInRow - 1)) / colsInRow
  }px)`
}

export const themeSpacingToPxConverter = (spacing: string): number => {
  return Number(spacing.slice(0, -2))
}

import React from "react"
import clsx from "clsx"

import { IIconProps } from "./types"
import classes from "./ChevronIcon.module.scss"

type TDirection = "top" | "left" | "right" | "bottom"

const ChevronIcon: React.FC<IIconProps & { direction?: TDirection }> = ({
  color = "currentColor",
  height = "100%",
  width = "100%",
  direction = "right",
}) => {
  const className = clsx({
    [classes.left]: direction === "left",
    [classes.top]: direction === "top",
    [classes.right]: direction === "right",
    [classes.bottom]: direction === "bottom",
  })

  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 11 19"
    >
      <path
        fill={color}
        fillRule="evenodd"
        d="M17.977 0L9.68 7.242 1.383 0 0 1.207 9.68 9.656 19.36 1.207z"
        transform="rotate(-90 10.173 9.252)"
      />
    </svg>
  )
}

export default ChevronIcon

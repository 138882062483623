import React from "react"
import { useTheme } from "../MaterialUI"
import { IIconProps } from "./types"

const MailIcon: React.FC<IIconProps> = ({
  width = "100%",
  height = "100%",
}) => {
  const { palette } = useTheme()
  return (
    <svg width={width} height={height} viewBox="0 0 24 24">
      <g fill="none">
        <path
          fill="#A0D5D5"
          fillOpacity=".8"
          d="M15.833 15.201l-3.428-2.987c-.223-.194-.585-.194-.809 0-.107.093-.167.22-.167.351v6.963c0 .214.157.403.39.47.059.018.12.027.181.027.18 0 .35-.074.457-.199l3.429-3.976c.17-.197.148-.474-.053-.649z"
          transform="translate(0 1)"
        />
        <path
          fill={palette.primary.main}
          d="M14.466 14.204l-3.428-.974c-.299-.085-.623.053-.723.308-.039.1-.04.208 0 .308l2.285 5.846c.078.199.297.333.543.333h.035c.259-.014.474-.174.525-.39l1.143-4.871c.057-.242-.106-.482-.38-.56z"
          transform="translate(0 1)"
        />
        <path
          fill="#A0D5D5"
          d="M23.781-.343c-.164-.113-.38-.122-.552-.024L3.685 10.747c-.246.14-.33.449-.187.69.063.107.164.187.283.226l9.237 3.025 7.18 3.023c.26.11.563-.01.675-.266.015-.036.026-.073.033-.111L23.992.159c.035-.194-.047-.39-.21-.502z"
          transform="translate(0 1)"
        />
        <g fill={palette.primary.main}>
          <path
            d="M23.86.821c.161-.226.11-.54-.113-.703-.2-.145-.474-.122-.646.054L10.087 14.036l3.756 1.31L23.86.821zM1.22 18.867c-.126 0-.247-.048-.339-.133-.249-.232-.494-.47-.734-.713-.196-.197-.196-.516-.001-.713.195-.198.511-.198.706-.001.232.234.467.463.706.685.203.188.216.507.03.712-.095.104-.229.163-.369.163zM8.018 22.188c-.48-.001-.96-.051-1.431-.15-.272-.045-.457-.304-.412-.579.044-.274.3-.46.573-.416l.039.008c.406.085.82.128 1.234.13h.228c.26-.026.49.167.516.43l.002.054c.01.278-.205.512-.48.522h-.26l-.009.001zm-3.576-.964c-.08 0-.158-.02-.23-.057-.49-.26-.964-.55-1.418-.87-.22-.166-.267-.482-.102-.705.157-.213.45-.267.671-.123.418.298.855.567 1.308.807.244.128.34.432.213.68-.085.167-.256.272-.443.272l.001-.004zM10.584 21.648c-.276.002-.5-.221-.503-.5-.002-.203.118-.388.304-.468.287-.124.566-.267.835-.427.24-.136.545-.048.679.195.13.235.054.53-.172.673-.306.183-.624.345-.95.487-.061.026-.127.04-.193.04z"
            transform="translate(0 1) translate(0 -.435)"
          />
        </g>
      </g>
    </svg>
  )
}

export default MailIcon

import { createContext } from "react"
import { IDomRefsContext } from "./types"

const DomRefsContext = createContext<IDomRefsContext>({
  refsMap: {},
  addRef: () => ({}),
  delRef: () => ({}),
  getRefHeight: () => 0,
  withFiltersPortal: { current: false },
})

export default DomRefsContext

import clsx from "clsx"
import React, { ReactNode, useEffect, useState } from "react"
import { MUIDrawer } from "../MaterialUI"
import classes from "./Drawer.module.scss"

type TDrawerVariant = "permanent" | "temporary" | "persistent"

type TAnchorVariant = "top" | "left" | "bottom" | "right"

interface IDrawerProps {
  opened: boolean
  toggleDrawer: (opened: boolean) => void
  anchor: TAnchorVariant
  testId: string
  children: ReactNode
}

const Drawer: React.FC<IDrawerProps> = ({
  opened,
  toggleDrawer,
  anchor,
  testId,
  children,
}) => {
  const [variant, setVariant] = useState<TDrawerVariant>("persistent")

  useEffect(() => {
    const $freshworksContainer = document.querySelector(
      "#freshworks-container"
    ) as HTMLElement
    if ($freshworksContainer) {
      $freshworksContainer.style.display = opened ? "none" : "block"
    }

    if (opened) {
      setVariant("temporary")
    } else {
      setTimeout(() => {
        setVariant("persistent")
      }, 100)
    }
  }, [opened])

  const rootClasses = clsx({
    [classes.anchorLeft]: anchor === "left",
    [classes.anchorBottom]: anchor === "bottom",
  })

  return (
    <MUIDrawer
      anchor={anchor}
      open={opened}
      variant={variant}
      onClose={() => toggleDrawer(false)}
      classes={{
        paper: rootClasses,
      }}
      PaperProps={{
        // @ts-ignore
        "data-testid": testId,
      }}
    >
      {children}
    </MUIDrawer>
  )
}

export default Drawer

export const CONFIG = {
  APP_BAR: {
    height: 64,
  },
  LOGO: {
    height: 31,
    width: 155,
  },
  OFFER_BAR: {
    height: 47,
  },
  BANNER: {
    height: 53,
  },
}

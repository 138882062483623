import React from "react"
import clsx from "clsx"

import { useTranslations } from "next-intl"
import { Box, Typography } from "../MaterialUI"
import AdviqoButton from "../AdviqoButton"
import Paper from "../Paper"
import SuccessCircleIcon from "../QuesticoIcon/SuccessCircleIcon"
import classes from "./SuccessSection.module.scss"

interface ISuccessSectionProps {
  icon?: React.ReactNode
  title?: string
  body?: string
  /**
   * bodyNode - optional node.
   * If it is not empty, it will be rendered. Otherwise, body field (string) will be rendered.
   */
  bodyNode?: React.ReactNode
  renderActions?: ({
    btnClassName,
  }: {
    btnClassName: string
  }) => React.ReactNode
  rootClassName?: string
  paperBodyClassName?: string
  buttonClassName?: string
  titleClassName?: string
  bodyClassName?: string
  iconClassName?: string
  testId?: string
  loading?: boolean
}

const SuccessSection: React.FC<ISuccessSectionProps> = ({
  icon,
  title,
  body,
  bodyNode,
  renderActions,
  rootClassName,
  paperBodyClassName,
  buttonClassName,
  titleClassName,
  bodyClassName,
  iconClassName,
  testId,
  loading,
}) => {
  const bodyResult = bodyNode || body
  const translate = useTranslations()

  return (
    <Paper
      rootClassName={rootClassName}
      testId={testId || "success-section"}
      bodyClassName={clsx(classes.paperBody, paperBodyClassName)}
      loading={loading}
    >
      <Box
        className={clsx(classes.icon, iconClassName)}
        data-testid="success-section-icon"
      >
        {icon || <SuccessCircleIcon color="#34e0a1" />}
      </Box>
      {title && (
        <Typography
          className={clsx(classes.title, titleClassName)}
          data-testid="success-section-title"
        >
          {title}
        </Typography>
      )}
      {bodyResult && (
        <Typography
          component="div"
          className={clsx(classes.body, bodyClassName)}
          data-testid="success-section-body"
        >
          {bodyResult}
        </Typography>
      )}
      {renderActions ? (
        renderActions({ btnClassName: clsx(classes.button, buttonClassName) })
      ) : (
        <>
          <AdviqoButton
            to="/"
            buttonClassName={clsx(classes.button, buttonClassName)}
            label={translate("buttons.backToHomePage")}
            labelCentered
          />
          <AdviqoButton
            to="/"
            buttonClassName={clsx(classes.button, buttonClassName)}
            label="Berater finden"
            variant="outlined"
            labelCentered
          />
        </>
      )}
    </Paper>
  )
}

export default SuccessSection

import React from "react"
import clsx from "clsx"

import { Box } from "../MaterialUI"
import Paper from "../Paper"
import AnimatedIcon, { spinnerConfig } from "../AnimatedIcon"
import classes from "./Loader.module.scss"

interface IProps {
  body?: string
  /**
   * bodyNode - optional node.
   * If it is not empty, it will be rendered. Otherwise, body field (string) will be rendered.
   */
  bodyNode?: React.ReactNode
  rootClassName?: string
  textClassName?: string
  iconClassName?: string
  testId?: string
}

const Loader: React.FC<IProps> = ({
  body,
  bodyNode,
  rootClassName,
  textClassName,
  iconClassName,
}) => {
  const bodyResult = bodyNode || body

  return (
    <Paper
      rootClassName={clsx(classes.root, rootClassName)}
      bodyClassName={classes.paperBody}
    >
      <AnimatedIcon
        type="spinner"
        config={spinnerConfig}
        className={iconClassName}
      />
      {bodyResult && (
        <Box className={clsx(classes.spinnerText, textClassName)}>
          {bodyResult}
        </Box>
      )}
    </Paper>
  )
}

export default Loader

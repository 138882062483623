import React from "react"
import clsx from "clsx"

import { Typography, useTheme } from "../MaterialUI"
import { toPriceString } from "../../../utils/utils"
import classes from "./ProductPriceString.module.scss"

interface PriceChunkProps {
  striken?: boolean
  highlighted?: boolean
  hasStrikethrough?: boolean
  noGutter?: boolean
  color?: string
  children: React.ReactNode
}

interface IProductPriceStringProps {
  price: number
  priceSale: number | null
  allowHighlight: boolean
  withPerMin?: boolean
  priceTextColor?: string
  /** `false` by default. Adds prefix "ab " before the price */
  withPrefix?: boolean
  isStrikeThrough?: boolean
}

const PriceChunk: React.FC<PriceChunkProps> = ({
  children,
  striken = false,
  highlighted = false,
  hasStrikethrough = false,
  noGutter = false,
  color,
}) => {
  const className = clsx(classes.priceChunk, {
    [classes.noGutter]: noGutter,
    [classes.striken]: striken,
  })
  const theme = useTheme()

  return (
    <Typography
      className={className}
      component="span"
      display="inline"
      sx={{
        color: () => {
          if (color) return color

          return hasStrikethrough || !highlighted
            ? "inherit"
            : theme.palette.text.primary
        },
      }}
    >
      {children}
    </Typography>
  )
}

const ProductPriceString: React.FC<IProductPriceStringProps> = ({
  price,
  priceSale,
  allowHighlight,
  withPerMin = true,
  withPrefix = false,
  priceTextColor,
  isStrikeThrough = undefined,
}) => {
  const hasStrikethrough =
    typeof isStrikeThrough !== "undefined"
      ? isStrikeThrough
      : priceSale !== null
  const hideSuffix = !withPerMin

  return (
    <>
      {withPrefix && (
        <PriceChunk noGutter color={priceTextColor}>
          ab&nbsp;
        </PriceChunk>
      )}
      {hasStrikethrough && (
        <PriceChunk striken hasStrikethrough={hasStrikethrough}>
          {toPriceString(price)}
        </PriceChunk>
      )}
      <PriceChunk highlighted={allowHighlight} color={priceTextColor}>
        {toPriceString(hasStrikethrough ? priceSale! : price)}
      </PriceChunk>
      {hideSuffix ? null : (
        <PriceChunk
          noGutter
          highlighted={allowHighlight}
          color={priceTextColor}
        >
          /Min.
        </PriceChunk>
      )}
    </>
  )
}

export default ProductPriceString

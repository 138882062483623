import React from "react"
import clsx from "clsx"

import { Typography } from "../MaterialUI"
import classes from "./PaperBodyText.module.scss"

interface IPaperBodyTextProps {
  gutterBottom?: boolean
  children: React.ReactNode
}

const PaperBodyText: React.FC<IPaperBodyTextProps> = ({
  children,
  gutterBottom = true,
}) => {
  const rootClassName = clsx(classes.paperBodytext, {
    [classes.noGutter]: gutterBottom === false,
  })

  return (
    <Typography gutterBottom={gutterBottom} className={rootClassName}>
      {children}
    </Typography>
  )
}

export default PaperBodyText
